import { useQuery, useMutation, useInfiniteQuery, useQueryClient, type UseQueryOptions } from '@tanstack/react-query';
import type { GoogleDriveFile, FileSearchParams, UploadResponse, SharePermission, ShareResponse } from '@/types/drive';
import useAxios from './use-axios';

type FileUploadData = {
  file: File;
  folderName?: string;
};

type MultipleFileUploadData = {
  files: File[];
  folderName?: string;
  onProgress?: (progress: Record<string, number>) => void;
};

type DriveListResponse = {
  files: GoogleDriveFile[];
  nextPageToken?: string;
};

type DeleteFileResponse = {
  success: boolean;
  message: string;
};

export const DRIVE_QUERY_KEYS = {
  files: {
    all: ['driveFiles'] as const,
    list: (params: FileSearchParams) => [...DRIVE_QUERY_KEYS.files.all, 'list', params] as const,
    detail: (fileId: string) => [...DRIVE_QUERY_KEYS.files.all, 'detail', fileId] as const,
  },
} as const;

export function useListFiles(params: FileSearchParams) {
  const axios = useAxios();

  return useInfiniteQuery<DriveListResponse>({
    queryKey: DRIVE_QUERY_KEYS.files.list(params),
    queryFn: async ({ pageParam }) => {
      const { data } = await axios.get<DriveListResponse>('/drive/list', {
        params: {
          ...params,
          pageToken: pageParam,
        },
      });
      return data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPageToken;
    },
    initialPageParam: undefined,
  });
}

export function useUploadFile() {
  const axios = useAxios();

  return useMutation<UploadResponse, Error, FileUploadData>({
    mutationFn: async ({ file, folderName }) => {
      const formData = new FormData();
      formData.append('file', file);
      if (folderName)
        formData.append('folderName', folderName);

      const { data } = await axios.post<UploadResponse>(
        '/drive/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    },
  });
}

export function useUploadMultipleFiles() {
  const axios = useAxios();

  return useMutation<UploadResponse[], Error, MultipleFileUploadData>({
    mutationFn: async ({ files, folderName, onProgress }) => {
      const formData = new FormData();
      for (const file of files) formData.append('files', file);
      if (folderName)
        formData.append('folderName', folderName);

      // Initialize progress for all files
      if (onProgress) {
        const initialProgress = Object.fromEntries(
          files.map((file) => [file.name, 0]),
        );
        onProgress(initialProgress);
      }

      const { data } = await axios.post<UploadResponse[]>(
        '/drive/upload-multiple',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total && onProgress) {
              const overallProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

              // Keep progress at 99% during server processing
              const progress = overallProgress >= 100 ? 99 : overallProgress;

              // Distribute progress evenly across files
              const fileProgresses = Object.fromEntries(
                files.map((file) => [
                  file.name,
                  progress,
                ]),
              );

              onProgress(fileProgresses);
            }
          },
        },
      );

      // Set final progress to 100% after server response
      if (onProgress) {
        const finalProgress = Object.fromEntries(
          files.map((file) => [file.name, 100]),
        );
        onProgress(finalProgress);
      }

      return data;
    },
  });
}

export function useDownloadFile(
  fileId: string,
  options?: Omit<UseQueryOptions<Blob, Error>, 'queryKey' | 'queryFn'>,
) {
  const axios = useAxios();

  return useQuery<Blob, Error>({
    queryKey: DRIVE_QUERY_KEYS.files.detail(fileId),
    queryFn: async () => {
      const response = await axios.get(`/drive/download/${fileId}`, {
        responseType: 'blob',
      });
      return response.data;
    },
    enabled: !!fileId,
    ...options,
  });
}

export function useDeleteFile() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation<DeleteFileResponse, Error, string>({
    mutationFn: async (fileId) => {
      const { data } = await axios.delete<DeleteFileResponse>(`/drive/delete/${fileId}`);
      return data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: DRIVE_QUERY_KEYS.files.all });
    },
  });
}

export function useShareFile() {
  const axios = useAxios();

  return useMutation<ShareResponse, Error, { fileId: string; permission: SharePermission }>({
    mutationFn: async ({ fileId, permission }) => {
      const { data } = await axios.post<ShareResponse>(`/drive/share/${fileId}`, permission);
      return data;
    },
  });
}
