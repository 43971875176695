import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { UploadedFilesCard } from '@/components/card-uploaded-files';
import Container from '@/components/container';
import { FileUploader } from '@/components/file-uploader';
import { FtldButton } from '@/components/ftld-button';
import { Input } from '@/components/ui/input';
import { useUploadMultipleFiles, useListFiles, DRIVE_QUERY_KEYS } from '@/hooks/use-drive';

function TasksPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({});
  const [isProcessing, setIsProcessing] = useState(false);
  const uploadMutation = useUploadMultipleFiles();
  const queryClient = useQueryClient();

  // Fetch all files from Drive
  // TODO: In the future, we'll fetch files from task-specific folders
  const { data: fileList } = useListFiles({
    pageSize: 50,
  });

  const handleFilesSelected = (files: File[]) => {
    setSelectedFiles(files);
    // Reset progress when new files are selected
    setUploadProgress({});
    setIsProcessing(false);
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      toast.error('Please select files to upload');
      return;
    }

    try {
      setIsProcessing(true);
      // TODO: In the future, we'll upload to task-specific folders instead of root
      const responses = await uploadMutation.mutateAsync(
        {
          files: selectedFiles,
          onProgress: (progresses) => {
            setUploadProgress(progresses);
          },
        },
        {
          onError: (error) => {
            console.error('Upload failed:', error);
            throw new Error('Failed to upload files to Google Drive');
          },
        },
      );

      // Check if any uploads failed
      const failedUploads = responses.filter((response) => !response.success);
      if (failedUploads.length > 0) {
        const errorMessages = failedUploads
          .map((response) => response.error || 'Unknown error')
          .join(', ');
        throw new Error(`Some files failed to upload: ${errorMessages}`);
      }

      // Invalidate and refetch the file list
      await queryClient.invalidateQueries({ queryKey: DRIVE_QUERY_KEYS.files.all });

      toast.success('Files uploaded successfully');
      setSelectedFiles([]); // Clear selected files after successful upload
      setUploadProgress({}); // Reset progress
    } catch (error) {
      console.error('Upload error:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to upload files');
      setUploadProgress({}); // Reset progress on error
    } finally {
      setIsProcessing(false);
    }
  };

  // Use Google Drive files directly
  const uploadedFiles = fileList?.pages.flatMap((page) => page.files) || [];

  const isUploading = uploadMutation.isPending || isProcessing;
  const hasFiles = selectedFiles.length > 0;

  return (
    <Container className="mt-8 pb-12">
      <h1 className="mb-6 text-3xl font-bold">Tasks</h1>
      <div className="mb-6">
        <Input
          type="search"
          placeholder="Search tasks..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col">
          <FileUploader
            value={selectedFiles}
            onValueChange={handleFilesSelected}
            disabled={isUploading}
            progresses={uploadProgress}
          />
          <div className="mt-4">
            <FtldButton
              onClick={handleUpload}
              disabled={isUploading || !hasFiles}
            >
              {isUploading ? 'Uploading...' : 'Upload'}
            </FtldButton>
          </div>
        </div>

        <UploadedFilesCard uploadedFiles={uploadedFiles} />
      </div>
    </Container>
  );
}

export default TasksPage;
