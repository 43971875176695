import type { ICellRendererParams, ColDef } from '@ag-grid-community/core';
import { format } from 'date-fns';

// define the row data type
type RowData = {
  id: number;
  domain: string;
  registrantOrg: string;
  registrar: string;
  createDate: string;
  registryStatus: string;
  websiteStatus: string;
};

// Cell Renderer functions
function DomainCell({ value }: ICellRendererParams<RowData, string>) {
  return (
    <div className="cursor-pointer font-medium text-primary hover:underline">
      {value}
    </div>
  );
}

function CreateDateCell({ value }: ICellRendererParams<RowData, string>) {
  if (!value || typeof value !== 'string') {
    return <div className="text-muted-foreground">Invalid date</div>;
  }

  const date = new Date(value);
  if (Number.isNaN(date.getTime())) {
    return <div className="text-muted-foreground">Invalid date</div>;
  }

  return <div className="text-muted-foreground">{format(date, 'MMM d, yyyy')}</div>;
}

function RegistrantOrgCell({ value }: ICellRendererParams<RowData, string>) {
  return (
    <div className="flex items-center gap-2">
      <div className="flex size-8 min-h-8 min-w-8 items-center justify-center rounded-full bg-primary/10">
        {String(value).charAt(0)}
      </div>
      <span>{value}</span>
    </div>
  );
}

function RegistrarCell({ value }: ICellRendererParams<RowData, string>) {
  return <div className="text-muted-foreground">{value}</div>;
}

function RegistryStatusCell({ value }: ICellRendererParams<RowData, string>) {
  const statusStyles = {
    Active: 'bg-green-100 text-green-800',
    Pending: 'bg-yellow-100 text-yellow-800',
    Expired: 'bg-red-100 text-red-800',
    TransferLock: 'bg-blue-100 text-blue-800',
    Suspended: 'bg-orange-100 text-orange-800',
  };

  return (
    <span className={`rounded-full px-2 py-1 text-xs font-medium ${statusStyles[value as keyof typeof statusStyles]}`}>
      {value}
    </span>
  );
}

function WebsiteStatusCell({ value }: ICellRendererParams<RowData, string | string[]>) {
  const statusStyles = {
    clientTransferProhibited: 'bg-blue-100 text-blue-800',
    clientUpdateProhibited: 'bg-blue-200 text-blue-800',
    ok: 'bg-green-100 text-green-800',
    pendingCreate: 'bg-yellow-100 text-yellow-800',
    redemptionPeriod: 'bg-orange-100 text-orange-800',
    inactive: 'bg-red-100 text-red-800',
    default: 'bg-gray-100 text-gray-800',
  };

  const getDisplayName = (status: string) =>
    status.replaceAll(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

  const statuses = Array.isArray(value) ? value : [value];

  return (
    <div className="flex flex-wrap items-center justify-center gap-1 py-2">
      {statuses.map((status, index) => (
        <span
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={`rounded-full px-2 py-1 text-xs font-medium ${statusStyles[status as keyof typeof statusStyles] || statusStyles.default
          }`}
        >
          {getDisplayName(status as string)}
        </span>
      ))}
    </div>
  );
}

// Column Definitions
export const domainColumnDefs: ColDef<RowData>[] = [
  {
    field: 'domain',
    headerName: 'Domain',
    width: 200,
    cellRenderer: DomainCell,
  },
  {
    field: 'registrantOrg',
    headerName: 'Registrant Organization',
    width: 250,
    cellRenderer: RegistrantOrgCell,
  },
  {
    field: 'registrar',
    headerName: 'Registrar',
    width: 200,
    cellRenderer: RegistrarCell,
  },
  {
    field: 'createDate',
    headerName: 'Create Date',
    width: 150,
    cellRenderer: CreateDateCell,
  },
  {
    field: 'registryStatus',
    headerName: 'Registry Status',
    width: 150,
    cellRenderer: RegistryStatusCell,
  },
  {
    field: 'websiteStatus',
    headerName: 'Website Status',
    width: 200,
    cellRenderer: WebsiteStatusCell,
  },
];
