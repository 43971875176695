import type { ColDef } from '@ag-grid-community/core';
import { format } from 'date-fns';

export type PreviewDomain = {
  id: string;
  domain: string;
  registrantOrg: string;
  registrar: string;
  tldType: string;
  geographic: string;
  lastVerifiedAt: string;
  status: string;
};

export const previewColumnDefs: ColDef<PreviewDomain>[] = [
  {
    field: 'domain',
    headerName: 'Domain',
    flex: 1.5,
    sortable: true,
    filter: true,
  },
  {
    field: 'registrantOrg',
    headerName: 'Organization',
    flex: 2,
    sortable: true,
    filter: true,
  },
  {
    field: 'registrar',
    headerName: 'Registrar',
    flex: 1.5,
    sortable: true,
    filter: true,
  },
  {
    field: 'tldType',
    headerName: 'TLD Type',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'geographic',
    headerName: 'Geographic',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'lastVerifiedAt',
    headerName: 'Last Verified',
    flex: 1.5,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return format(new Date(params.value), 'MMM d, yyyy');
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    sortable: true,
    filter: true,
  },
];

// Sample data for development
export const samplePreviewData: PreviewDomain[] = [
  {
    id: '1',
    domain: 'chase.bank',
    registrantOrg: 'JPMorgan Chase Bank, N.A.',
    registrar: 'csc-enterprise',
    tldType: 'bank',
    geographic: 'us',
    lastVerifiedAt: '2023-05-15T00:00:00Z',
    status: 'active',
  },
  {
    id: '2',
    domain: 'statefarm.insurance',
    registrantOrg: 'State Farm Insurance',
    registrar: 'csc-corporate',
    tldType: 'insurance',
    geographic: 'us',
    lastVerifiedAt: '2023-02-10T00:00:00Z',
    status: 'active',
  },
  {
    id: '3',
    domain: 'wellsfargo.bank',
    registrantOrg: 'Wells Fargo Bank',
    registrar: 'csc-enterprise',
    tldType: 'bank',
    geographic: 'us',
    lastVerifiedAt: '2023-01-01T00:00:00Z',
    status: 'active',
  },
  {
    id: '4',
    domain: 'barclays.bank',
    registrantOrg: 'Barclays Bank PLC',
    registrar: 'csc-corporate',
    tldType: 'bank',
    geographic: 'gb',
    lastVerifiedAt: '2023-08-20T00:00:00Z',
    status: 'active',
  },
  {
    id: '5',
    domain: 'allianz.insurance',
    registrantOrg: 'Allianz SE',
    registrar: 'markmonitor',
    tldType: 'insurance',
    geographic: 'eu',
    lastVerifiedAt: '2023-06-15T00:00:00Z',
    status: 'active',
  },
];
