import { useForm } from 'react-hook-form';
import FormInput from '@/components/form-components/form-input';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import type { CompanyInput } from '@/types/company';

type CreateCompanySheetProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

function CreateCompanySheet({ open, onOpenChange }: CreateCompanySheetProps) {
  const { control, handleSubmit } = useForm<CompanyInput>({
    defaultValues: {
      primaryAddress: {},
      regulatoryAuthority: {},
    },
  });

  function onSubmit(data: CompanyInput) {
    console.log('Form submitted:', data);
    onOpenChange(false);
  }

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side="right"
        className="w-full max-w-full sm:max-w-lg"
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <SheetHeader>
          <SheetTitle>Create Company</SheetTitle>
        </SheetHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="h-[calc(100vh-8rem)] space-y-6 overflow-y-auto pr-6 pt-8">
          <FormInput<CompanyInput>
            control={control}
            name="legalName"
            label="Legal Name"
            required
            rules={{ required: 'Legal name is required' }}
          />

          <div className="space-y-4">
            <h3 className="font-medium">Primary Address</h3>
            <FormInput<CompanyInput>
              control={control}
              name="primaryAddress.streetAddress"
              label="Street Address"
            />
            <FormInput<CompanyInput>
              control={control}
              name="primaryAddress.city"
              label="City"
            />
            <div className="grid grid-cols-2 gap-4">
              <FormInput<CompanyInput>
                control={control}
                name="primaryAddress.state"
                label="State"
              />
              <FormInput<CompanyInput>
                control={control}
                name="primaryAddress.postalCode"
                label="Postal Code"
              />
            </div>
            <FormInput<CompanyInput>
              control={control}
              name="primaryAddress.country"
              label="Country"
            />
          </div>

          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="regulatory">
              <AccordionTrigger>Regulatory Information</AccordionTrigger>
              <AccordionContent>
                <div className="space-y-4 pt-4">
                  <FormInput<CompanyInput>
                    control={control}
                    name="regulatoryAuthority.name"
                    label="Authority Name"
                  />
                  <FormInput<CompanyInput>
                    control={control}
                    name="regulatoryAuthority.jurisdiction"
                    label="Jurisdiction"
                  />
                  <FormInput<CompanyInput>
                    control={control}
                    name="regulatoryAuthority.registrationNumber"
                    label="Registration Number"
                  />
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>

          <div className="pt-4">
            <Button type="submit" className="w-full">
              Create Company
            </Button>
          </div>
        </form>
      </SheetContent>
    </Sheet>
  );
}

export default CreateCompanySheet;
