import type { Color, PaletteColorOptions } from '@mui/material';
import { getContrastRatio } from '@mui/material/styles';

// We use this as a typescript workaround when we want to use a colors' extended palette
// example: (primary as ColorPartial)['50']
export type ColorPartial = Partial<Color>;

const primaryMain: string = '#739c78';
const secondaryMain: string = '#fec748';

const primaryContrastText = getContrastRatio(primaryMain, '#fff') > 3 ? '#fff' : '#111';
const secondaryContrastText = getContrastRatio(secondaryMain, '#fff') > 3 ? '#fff' : '#111';

export const primary: PaletteColorOptions = {
  main: '#739c78',
  light: '#add2ac',
  dark: '#38593c',
  50: '#eef3ef',
  100: '#d5e1d7',
  200: '#b9cebc',
  300: '#9dbaa1',
  400: '#88ab8c',
  500: '#739c78',
  600: '#6b9470',
  700: '#608a65',
  800: '#56805b',
  900: '#436e48',
  A100: '#c9ffcf',
  A200: '#96ffa2',
  A400: '#63ff74',
  A700: '#49ff5e',
  contrastText: primaryContrastText,
};

export const secondary: PaletteColorOptions = {
  main: '#fec748',
  light: '#ffe29f',
  dark: '#ebb331',
  50: '#fff8e9',
  100: '#ffeec8',
  200: '#ffe3a4',
  300: '#fed87f',
  400: '#fecf63',
  500: '#fec748',
  600: '#fec141',
  700: '#feba38',
  800: '#feb330',
  900: '#fda621',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffebce',
  A700: '#ffe0b5',
  contrastText: secondaryContrastText,
};
