import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useBreadcrumb } from '@/components/breadcrumb-provider';
import type { BreadcrumbSegment } from '@/components/breadcrumb-provider/types';
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Separator } from '@/components/ui/separator';
import { SidebarTrigger } from '@/components/ui/sidebar';

/**
 * Processes the current pathname into breadcrumb segments
 * @param pathname - The current URL pathname
 * @param labels - Custom labels for specific routes
 * @returns Array of path segments for breadcrumb navigation
 */
function getPathSegments(pathname: string, labels: Record<string, { name: string }>): BreadcrumbSegment[] {
  // Extract segments after the initial slash, removing empty strings
  const segments = pathname.split('/').filter(Boolean).slice(1);

  return segments.map((path, index) => {
    // Build the full path for this segment
    const fullPath = `/app/${segments.slice(0, index + 1).join('/')}`;
    const customLabel = labels[fullPath];

    // helper for clarity
    const capitalizeFirstLetter = (str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return {
      name: customLabel?.name ?? capitalizeFirstLetter(path),
      path: fullPath,
    };
  });
}

type BreadcrumbNavigationProps = {
  /** Array of path segments to display in the breadcrumb */
  segments: BreadcrumbSegment[];
};

/**
 * Renders a breadcrumb navigation from an array of path segments
 * The last segment is rendered as the current page
 */
function BreadcrumbNavigation({ segments }: BreadcrumbNavigationProps) {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        {segments.map((item, index) => (
          <React.Fragment key={item.path}>
            <BreadcrumbItem>
              {index === segments.length - 1 ? (
                // Current page - rendered as non-interactive
                <BreadcrumbPage className="text-lg font-semibold">
                  {item.name}
                </BreadcrumbPage>
              ) : (
                // Navigation link for previous segments
                <BreadcrumbLink asChild>
                  <Link to={item.path} className="text-lg font-medium text-muted-foreground">
                    {item.name}
                  </Link>
                </BreadcrumbLink>
              )}
            </BreadcrumbItem>
            {index < segments.length - 1 && <BreadcrumbSeparator />}
          </React.Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
}

/**
 * Application header component that displays a collapsible sidebar trigger
 * and breadcrumb navigation based on the current route
 */
function AppHeader() {
  const location = useLocation();
  const { labels } = useBreadcrumb();
  const paths = getPathSegments(location.pathname, labels);

  return (
    <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
      <div className="flex items-center gap-2 px-4">
        <SidebarTrigger className="-ml-1" />
        <Separator orientation="vertical" className="mr-2 h-4" />
        <BreadcrumbNavigation segments={paths} />
      </div>
    </header>
  );
}

export default AppHeader;
