import { cva } from 'class-variance-authority';

export const ftldButtonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-ftld-button border-2 text-sm font-semibold transition-colors duration-300 ease-in-out focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        contained: '',
        outlined: '',
        text: '',
      },
      size: {
        default: 'h-9 px-4 py-5',
        sm: 'h-8 px-3 text-xs',
        lg: 'h-10 px-8',
        icon: 'size-9',
      },
      colorVariant: {
        primary: '',
        secondary: '',
      },
    },
    compoundVariants: [
      // Primary color variants
      {
        variant: 'contained',
        colorVariant: 'primary',
        class: 'border-ftld-primary-dark bg-ftld-primary-dark text-white hover:border-ftld-primary hover:bg-ftld-primary active:border-ftld-primary-dark active:bg-ftld-primary-dark',
      },
      {
        variant: 'outlined',
        colorVariant: 'primary',
        class: 'border-ftld-primary-dark bg-transparent text-ftld-primary-dark hover:bg-ftld-primary hover:text-foreground active:bg-ftld-primary-300',
      },
      {
        variant: 'text',
        colorVariant: 'primary',
        class: 'border-transparent bg-transparent text-ftld-primary-dark hover:bg-ftld-primary-light active:bg-ftld-primary-100',
      },
      // Secondary color variants
      {
        variant: 'contained',
        colorVariant: 'secondary',
        class: 'border-ftld-secondary-dark bg-ftld-secondary-dark text-white hover:border-ftld-secondary hover:bg-ftld-secondary active:border-ftld-secondary-dark active:bg-ftld-secondary-dark',
      },
      {
        variant: 'outlined',
        colorVariant: 'secondary',
        class: 'border-ftld-secondary-dark bg-transparent text-ftld-secondary-dark hover:bg-ftld-secondary hover:text-foreground active:bg-ftld-secondary-300',
      },
      {
        variant: 'text',
        colorVariant: 'secondary',
        class: 'border-transparent bg-transparent text-ftld-secondary-dark hover:bg-ftld-secondary-light active:bg-ftld-secondary-100',
      },
    ],
    defaultVariants: {
      variant: 'contained',
      size: 'default',
      colorVariant: 'primary',
    },
  },
);
