import { Outlet } from 'react-router-dom';
import { toast } from 'sonner';
import AppHeader from '@/components/app-header';
import { AppSidebar } from '@/components/app-sidebar';
import { BreadcrumbProvider } from '@/components/breadcrumb-provider';
import { AppCommandPalette } from '@/components/command-palette/app-command-palette';
import { SidebarInset, SidebarProvider } from '@/components/ui/sidebar';
import { GridProvider, useGrid } from '@/contexts/grid-context';
import { useEnsureUser } from '@/hooks/use-ensure-user';

function AppContent() {
  const { clearGridSelection } = useGrid();
  const { isError } = useEnsureUser();

  // Show error toast if user verification fails
  if (isError) {
    toast.error(
      'Failed to verify user status. Some features may be unavailable.',
      { duration: 5000, id: 'user-verify-error' },
    );
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // Only deselect if clicking outside the grid
    if (
      event.target instanceof HTMLElement
      && !event.target.closest('.ag-root-wrapper')
    ) {
      clearGridSelection();
    }
  };

  return (
    <div className="flex min-h-svh flex-col" onClick={handleClick}>
      <SidebarProvider>
        <aside>
          <AppSidebar />
        </aside>
        <SidebarInset>
          <AppHeader />
          <Outlet />
        </SidebarInset>
      </SidebarProvider>
      <AppCommandPalette />
    </div>
  );
}

function AppLayout() {
  return (
    <BreadcrumbProvider>
      <GridProvider>
        <AppContent />
      </GridProvider>
    </BreadcrumbProvider>
  );
}

export default AppLayout;
