import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { GridApi } from '@ag-grid-community/core';
import { Plus, RotateCcw } from 'lucide-react';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useGrid } from '@/contexts/grid-context';
import type { ScheduledBatch } from './components/scheduler-grid-columns';
import { schedulerColumnDefs, sampleScheduledData } from './components/scheduler-grid-columns';

function SchedulerPage() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const gridApiRef = useRef<GridApi<ScheduledBatch> | null>(null);
  const { registerGridApi } = useGrid();

  const handleGridReady = (params: { api: GridApi<ScheduledBatch> }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  const clearFilters = () => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      setSearchQuery('');
    }
  };

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-3 flex justify-between">
        <div>
          <h1 className="mt-2 text-xl font-bold">Scheduled Batches</h1>
          <p className="text-sm text-muted-foreground">
            View and manage scheduled domain verification batches
          </p>
        </div>
        <div className="flex items-center gap-4">
          <Button
            onClick={() => navigate('/app/scheduler/schedule')}
            className="gap-2"
          >
            <Plus className="size-4" />
            Schedule New Batch
          </Button>
        </div>
      </div>

      <div className="mb-6 flex gap-4">
        <div className="flex-1">
          <Input
            type="search"
            placeholder="Search scheduled batches..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button
          variant="outline"
          onClick={clearFilters}
          className="gap-2"
        >
          <RotateCcw className="size-4" />
          Clear Filters
        </Button>
      </div>

      <AGDataGrid
        columnDefs={schedulerColumnDefs}
        rowData={sampleScheduledData}
        quickFilterText={searchQuery}
        onGridReady={handleGridReady}
      />
    </Container>
  );
}

export default SchedulerPage;
