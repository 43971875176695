import { Link, useLocation } from 'react-router-dom';
import { Collapsible } from '@/components/ui/collapsible';
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/components/ui/sidebar';
import { cn } from '@/lib/utils';
import type { LucideIcon } from 'lucide-react';

export function NavMain({
  items,
}: {
  items: {
    title: string;
    url: string;
    icon: LucideIcon;
    isActive?: boolean;
    items?: {
      title: string;
      url: string;
    }[];
  }[];
}) {
  const location = useLocation();

  return (
    <SidebarGroup>
      <SidebarMenu>
        {items.map((item) => {
          const isActive = location.pathname === item.url;

          return (
            <Collapsible key={item.title} asChild defaultOpen={item.isActive}>
              <SidebarMenuItem>
                <SidebarMenuButton
                  className={cn(
                    'font-medium transition duration-300 ease-in-out hover:bg-ftld-gray-light hover:text-ftld-secondary active:bg-ftld-gray-light active:text-ftld-secondary',
                    isActive && 'bg-ftld-gray-light text-ftld-secondary',
                  )}
                  asChild
                  tooltip={item.title}
                >
                  <Link to={item.url}>
                    <item.icon className={cn(isActive && 'text-ftld-secondary')} />
                    <span>{item.title}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </Collapsible>
          );
        })}
      </SidebarMenu>
    </SidebarGroup>
  );
}
