import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cn } from '@/lib/utils';
import type { VariantProps } from 'class-variance-authority';
import { ftldButtonVariants } from './button-variants';

type ButtonVariantProps = VariantProps<typeof ftldButtonVariants>;

export interface FtldButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'>,
  ButtonVariantProps {
  asChild?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const FtldButton = React.forwardRef<HTMLButtonElement, FtldButtonProps>(
  ({ className, variant, size, colorVariant, asChild = false, startIcon, endIcon, children, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(ftldButtonVariants({ variant, size, colorVariant, className }))}
        ref={ref}
        {...props}
      >
        {React.isValidElement(startIcon) && <span className="mr-2">{startIcon}</span>}
        {children}
        {React.isValidElement(endIcon) && <span className="ml-2">{endIcon}</span>}
      </Comp>
    );
  },
);

FtldButton.displayName = 'FtldButton';

export { FtldButton };
