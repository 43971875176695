import { useState } from 'react';
import type { ColDef } from '@ag-grid-community/core';
import { format } from 'date-fns';
import { Plus, Trash2 } from 'lucide-react';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { useNylasGrants } from '@/hooks/use-nylas-grants';
import CreateConnectionSheet from './components/create-connection-sheet';

function SettingsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateSheetOpen, setIsCreateSheetOpen] = useState(false);
  const [selectedGrant, setSelectedGrant] = useState<any | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { data: grants, isLoading, deleteGrant, isDeletingGrant } = useNylasGrants();

  const handleSelectionChanged = (event: any) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedGrant(selectedRows.length === 1 ? selectedRows[0] : null);
  };

  const handleDelete = async () => {
    if (selectedGrant) {
      deleteGrant(selectedGrant.id);
      setSelectedGrant(null);
      setIsDeleteDialogOpen(false);
    }
  };

  const nylasColumnDefs: ColDef[] = [
    {
      field: 'email',
      headerName: 'Email Address',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'provider',
      headerName: 'Provider',
      flex: 1,
    },
    {
      field: 'grantStatus',
      headerName: 'Status',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      flex: 1,
      valueFormatter: (params) => {
        return format(params.value * 1000, 'PPpp');
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      flex: 1,
      valueFormatter: (params) => {
        return format(params.value * 1000, 'PPpp');
      },
    },
  ];

  return (
    <Container className="mt-8 pb-12">
      <div className="mb-3 flex justify-between">
        <div>
          <h1 className="text-xl font-bold">Settings</h1>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
            <CardTitle>Connections</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="mb-4">
              <Input
                type="search"
                placeholder="Search connections..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <AGDataGrid
              columnDefs={nylasColumnDefs}
              rowData={grants}
              quickFilterText={searchQuery}
              rowSelection="single"
              onSelectionChanged={handleSelectionChanged}
              loadingOverlayComponent={isLoading ? 'Loading...' : undefined}
            />
            <div className="mt-4 flex justify-end gap-2">
              <Button
                className="ml-auto"
                onClick={() => setIsCreateSheetOpen(true)}
                size="sm"
              >
                <Plus className="mr-2 size-4" />
                Add New Connection
              </Button>
              <Button
                variant="destructive"
                onClick={() => setIsDeleteDialogOpen(true)}
                size="sm"
                disabled={!selectedGrant || isDeletingGrant}
              >
                <Trash2 className="mr-2 size-4" />
                Delete Connection
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>

      <CreateConnectionSheet
        open={isCreateSheetOpen}
        onOpenChange={setIsCreateSheetOpen}
      />

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Connection</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this connection? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
              disabled={isDeletingGrant}
            >
              {isDeletingGrant ? 'Deleting...' : 'Delete'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Container>
  );
}

export default SettingsPage;
