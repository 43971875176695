import React, { forwardRef } from 'react';
import { cn } from '@/lib/utils';

// Base64 encoded SVG placeholder
const defaultPlaceholder = `data:image/svg+xml;base64,${btoa(`
<svg width="400" height="400" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="400" height="400" fill="#F3F4F6"/>
  <path d="M156 172C156 179.732 149.732 186 142 186C134.268 186 128 179.732 128 172C128 164.268 134.268 158 142 158C149.732 158 156 164.268 156 172Z" fill="#D1D5DB"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M112 128C103.163 128 96 135.163 96 144V256C96 264.837 103.163 272 112 272H288C296.837 272 304 264.837 304 256V144C304 135.163 296.837 128 288 128H112ZM288 256H112L172.5 195.5L196 219L243.5 171.5L288 216V256Z" fill="#D1D5DB"/>
</svg>
`)}`;

/**
 * Valid options for the objectFit property.
 * @typedef {'contain' | 'cover' | 'fill' | 'none' | 'scale-down'} ObjectFitOptions
 */
export type ObjectFitOptions = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';

/**
 * Valid options for the placeholder property.
 * @typedef {'blur' | 'empty'} PlaceholderValue
 */
export type PlaceholderValue = 'blur' | 'empty';

/**
 * Props for the Image component.
 * @typedef {object} ImageProps
 * @property {string} src - The source URL of the image.
 * @property {string} alt - Alternative text for the image.
 * @property {(number | `${number}`)} [width] - The width of the image.
 * @property {(number | `${number}`)} [height] - The height of the image.
 * @property {'eager' | 'lazy'} [loading='lazy'] - Loading behavior of the image.
 * @property {PlaceholderValue} [placeholder='empty'] - Type of placeholder to show while loading.
 * @property {ObjectFitOptions} [objectFit='cover'] - How the image should be resized to fit its container.
 * @property {string} [className] - Additional CSS classes to apply to the container.
 * @property {string} [fallbackSrc] - URL for fallback image to show on error (defaults to built-in SVG).
 */
export type ImageProps = Omit<
  React.ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'alt' | 'width' | 'height' | 'loading' | 'objectFit'
> & {
  src: string;
  alt: string;
  width?: number | `${number}`;
  height?: number | `${number}`;
  loading?: 'eager' | 'lazy';
  placeholder?: PlaceholderValue;
  objectFit?: ObjectFitOptions;
  className?: string;
  fallbackSrc?: string;
};

/**
 * A flexible and feature-rich image component that extends the native img element.
 *
 * @component
 * @example
 * // Basic usage
 * <Image
 *   src="/path/to/image.jpg"
 *   alt="Description"
 *   width={400}
 *   height={300}
 * />
 *
 * @example
 * // With placeholder and custom object fit
 * <Image
 *   src="/path/to/image.jpg"
 *   alt="Description"
 *   width={400}
 *   height={300}
 *   placeholder="blur"
 *   objectFit="contain"
 *   className="rounded-lg"
 * />
 *
 * @throws {Error} Will throw an error if required props (src, alt) are not provided
 *
 * @param {ImageProps} props - The component props
 * @param {React.Ref<HTMLImageElement>} ref - Forward ref to the underlying img element
 * @returns {JSX.Element} The Image component
 */
const Image = forwardRef<HTMLImageElement, ImageProps>(
  (
    {
      src,
      alt,
      width,
      height,
      loading = 'lazy',
      placeholder = 'empty',
      objectFit = 'cover',
      className,
      fallbackSrc = defaultPlaceholder,
      ...props
    },
    ref,
  ) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    const handleLoad = () => {
      setIsLoading(false);
    };

    const handleError = () => {
      setIsLoading(false);
      setError(true);
    };

    return (
      <div
        className={cn(
          'relative overflow-hidden',
          isLoading && 'animate-pulse bg-gray-200',
          className,
        )}
        style={{
          width,
          height,
        }}
      >
        <img
          ref={ref}
          src={error ? fallbackSrc : src}
          alt={alt}
          width={width}
          height={height}
          loading={loading}
          onLoad={handleLoad}
          onError={handleError}
          className={cn(
            'transition-opacity duration-300',
            isLoading ? 'opacity-0' : 'opacity-100',
            objectFit === 'contain' && 'object-contain',
            objectFit === 'cover' && 'object-cover',
            objectFit === 'fill' && 'object-fill',
            objectFit === 'none' && 'object-none',
            objectFit === 'scale-down' && 'object-scale-down',
            'w-full h-full',
          )}
          {...props}
        />

        {placeholder === 'blur' && isLoading && (
          <div
            className="absolute inset-0 bg-gray-200/50 backdrop-blur-sm"
            aria-hidden="true"
          />
        )}
      </div>
    );
  },
);

Image.displayName = 'Image';

export default Image;
