// src/pages/app/deals/components/activity-panel.tsx
import { Activity } from 'lucide-react';
import { ScrollArea } from '@/components/ui/scroll-area';

interface ActivityPanelProps {
  dealId?: string;
}

export function ActivityPanel({ dealId }: ActivityPanelProps) {
  const activities = [
    {
      id: '1',
      action: 'Document edited',
      user: 'John Doe',
      timestamp: new Date().toISOString(),
    },
    {
      id: '2',
      action: 'Export.gov lookup performed',
      user: 'Jane Smith',
      timestamp: new Date().toISOString(),
    },
    // Add more activities as needed
  ];

  return (
    <ScrollArea className="h-full">
      <div className="space-y-4 p-4">
        {activities.map((activity) => (
          <div key={activity.id} className="flex items-start gap-3">
            <Activity className="mt-0.5 size-4 text-muted-foreground" />
            <div className="flex-1">
              <p className="text-sm">
                <span className="font-medium">{activity.user}</span>
                {' '}
                {activity.action}
              </p>
              <p className="text-xs text-muted-foreground">
                {new Date(activity.timestamp).toLocaleString()}
              </p>
            </div>
          </div>
        ))}
      </div>
    </ScrollArea>
  );
}
