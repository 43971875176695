import { useQuery } from '@tanstack/react-query';
import useAxios from './use-axios';

export interface Thread {
  id: string;
  subject: string;
  snippet: string;
  unread: boolean;
  starred: boolean;
  hasAttachments: boolean;
  participants: {
    name: string;
    email: string;
  }[];
  lastMessageTimestamp: number;
  messageIds: string[];
}

interface ThreadsResponse {
  threads: Thread[];
  nextPageToken: string | null;
  requestId: string;
}

interface UseNylasThreadsParams {
  grantId: string;
  limit?: number;
  pageToken?: string;
  unread?: boolean;
  subject?: string;
  hasAttachment?: boolean;
  starred?: boolean;
}

export const NYLAS_QUERY_KEYS = {
  threads: {
    all: ['nylasThreads'] as const,
    list: (params: UseNylasThreadsParams) => [...NYLAS_QUERY_KEYS.threads.all, params] as const,
  },
} as const;

export function useNylasThreads(params: UseNylasThreadsParams) {
  const axios = useAxios();
  const { grantId, ...queryParams } = params;

  return useQuery({
    queryKey: NYLAS_QUERY_KEYS.threads.list(params),
    queryFn: async () => {
      const { data } = await axios.get<ThreadsResponse>('/nylas/threads', {
        params: {
          grantId,
          ...queryParams,
        },
      });
      return data;
    },
    enabled: !!grantId,
  });
}
