// src/components/tiptap/extensions/export-gov-lookup/export-gov-lookup-component.tsx
import { useState } from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import type { Transaction } from '@tiptap/pm/state';
import { CheckCircle2, Lock, Search, Unlock, FileText, ArrowLeft, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';

type VerificationMode = 'choose' | 'api' | 'manual';
type ApiStep = 'search' | 'results' | 'verify';
type ManualStep = 'input' | 'verify';

export function ExportGovLookupComponent({ node, updateAttributes, editor, getPos }: any) {
  const [mode, setMode] = useState<VerificationMode>(node.attrs.mode || 'choose');
  const [apiStep, setApiStep] = useState<ApiStep>('search');
  const [manualStep, setManualStep] = useState<ManualStep>('input');
  const [isLoading, setIsLoading] = useState(false);

  const handleVerify = () => {
    updateAttributes({
      verified: true,
      verifiedBy: 'Current User',
      verificationDate: new Date().toISOString(),
    });
  };

  const handleUnlock = () => {
    updateAttributes({
      verified: false,
      verifiedBy: null,
      verificationDate: null,
      mode: 'choose',
    });
    setMode('choose');
    setApiStep('search');
    setManualStep('input');
  };

  const handleDelete = () => {
    if (!editor || typeof getPos !== 'function') return;
    
    const pos = getPos();
    editor.chain()
      .focus()
      .command(({ tr }: { tr: Transaction }) => {
        tr.delete(pos, pos + node.nodeSize);
        return true;
      })
      .run();
  };

  const handleApiSearch = async () => {
    setIsLoading(true);
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      setApiStep('results');
      updateAttributes({
        lookupDate: new Date().toISOString(),
        lookupResult: 'No matches found in export.gov database',
      });
    } catch (error) {
      console.error('API search failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    if (node.attrs.verified) {
      return (
        <div className="space-y-4">
          <div className="grid gap-3">
            <div>
              <Label className="text-xs font-normal text-muted-foreground">Entity Name</Label>
              <div className="mt-1.5 text-sm">{node.attrs.entityName}</div>
            </div>
            {node.attrs.lookupResult && (
              <div>
                <Label className="text-xs font-normal text-muted-foreground">Lookup Result</Label>
                <div className="mt-1.5 text-sm">{node.attrs.lookupResult}</div>
              </div>
            )}
            {node.attrs.verificationDetails && (
              <div>
                <Label className="text-xs font-normal text-muted-foreground">Verification Details</Label>
                <div className="mt-1.5 text-sm">{node.attrs.verificationDetails}</div>
              </div>
            )}
          </div>
          <Separator />
          <div className="grid gap-1.5 text-xs text-muted-foreground">
            <div className="flex items-center justify-between">
              <span>Verified by</span>
              <span className="font-medium text-foreground">{node.attrs.verifiedBy}</span>
            </div>
            <div className="flex items-center justify-between">
              <span>Verification date</span>
              <span className="font-medium text-foreground">
                {new Date(node.attrs.verificationDate).toLocaleString()}
              </span>
            </div>
          </div>
        </div>
      );
    }

    if (mode === 'choose') {
      return (
        <div className="grid gap-4">
          <Button
            variant="outline"
            className="justify-start gap-2"
            onClick={() => {
              setMode('api');
              updateAttributes({ mode: 'api' });
            }}
          >
            <Search className="size-4" />
            Search export.gov API
          </Button>
          <Button
            variant="outline"
            className="justify-start gap-2"
            onClick={() => {
              setMode('manual');
              updateAttributes({ mode: 'manual' });
            }}
          >
            <FileText className="size-4" />
            Enter details manually
          </Button>
        </div>
      );
    }

    if (mode === 'api') {
      return (
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              size="sm"
              className="h-7 px-2"
              onClick={() => {
                setMode('choose');
                setApiStep('search');
                updateAttributes({ mode: 'choose' });
              }}
            >
              <ArrowLeft className="size-4" />
            </Button>
            <span className="text-sm font-medium">API Verification</span>
          </div>
          
          {apiStep === 'search' && (
            <div className="space-y-4">
              <div>
                <Label className="text-xs font-normal text-muted-foreground">Entity Name</Label>
                <Input
                  value={node.attrs.entityName || ''}
                  onChange={(e) => updateAttributes({ entityName: e.target.value })}
                  placeholder="Enter entity name to search"
                  className="mt-1.5"
                />
              </div>
              <Button
                onClick={handleApiSearch}
                disabled={!node.attrs.entityName || isLoading}
                className="w-full"
              >
                {isLoading ? 'Searching...' : 'Search export.gov'}
              </Button>
            </div>
          )}

          {apiStep === 'results' && (
            <div className="space-y-4">
              <div className="rounded-md bg-muted p-3 text-sm">
                <p className="font-medium">Search Results</p>
                <p className="mt-1 text-muted-foreground">{node.attrs.lookupResult}</p>
                <p className="mt-1 text-xs text-muted-foreground">
                  Searched on: {new Date(node.attrs.lookupDate).toLocaleString()}
                </p>
              </div>
              <div>
                <Label className="text-xs font-normal text-muted-foreground">Verification Details</Label>
                <Input
                  value={node.attrs.verificationDetails || ''}
                  onChange={(e) => updateAttributes({ verificationDetails: e.target.value })}
                  placeholder="Add any verification details"
                  className="mt-1.5"
                />
              </div>
              <Button onClick={handleVerify} className="w-full gap-2">
                <CheckCircle2 className="size-4" />
                Sign off verification
              </Button>
            </div>
          )}
        </div>
      );
    }

    if (mode === 'manual') {
      return (
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              size="sm"
              className="h-7 px-2"
              onClick={() => {
                setMode('choose');
                setManualStep('input');
                updateAttributes({ mode: 'choose' });
              }}
            >
              <ArrowLeft className="size-4" />
            </Button>
            <span className="text-sm font-medium">Manual Verification</span>
          </div>

          {manualStep === 'input' && (
            <div className="space-y-4">
              <div>
                <Label className="text-xs font-normal text-muted-foreground">Entity Name</Label>
                <Input
                  value={node.attrs.entityName || ''}
                  onChange={(e) => updateAttributes({ entityName: e.target.value })}
                  placeholder="Enter entity name"
                  className="mt-1.5"
                />
              </div>
              <div>
                <Label className="text-xs font-normal text-muted-foreground">Verification Details</Label>
                <Input
                  value={node.attrs.verificationDetails || ''}
                  onChange={(e) => updateAttributes({ verificationDetails: e.target.value })}
                  placeholder="Add verification details"
                  className="mt-1.5"
                />
              </div>
              <Button
                onClick={handleVerify}
                disabled={!node.attrs.entityName}
                className="w-full gap-2"
              >
                <CheckCircle2 className="size-4" />
                Sign off verification
              </Button>
            </div>
          )}
        </div>
      );
    }
  };

  // Check if cursor is right before this node
  const isBeforeCursor = editor?.state.selection.$from.pos === getPos();

  return (
    <NodeViewWrapper>
      <Card className={cn(
        "border-muted bg-muted/30 transition-all duration-150",
        isBeforeCursor && !node.attrs.verified && "border-[#739c78]/40 bg-[#eef3ef] ring-2 ring-[#739c78]/40"
      )}>
        <CardHeader className="px-4 py-2">
          <CardTitle className="flex items-center justify-between text-base">
            <div className="flex items-center gap-2">
              <Search className="size-4" />
              Export.gov Verification
            </div>
            <div className="flex items-center gap-1">
              {!node.attrs.verified && (
                <Button 
                  variant="ghost" 
                  size="sm"
                  onClick={handleDelete}
                  className="h-7 px-2 text-muted-foreground hover:text-destructive"
                >
                  <Trash2 className="size-3.5" />
                </Button>
              )}
              {node.attrs.verified ? (
                <Button 
                  variant="ghost" 
                  size="sm" 
                  onClick={handleUnlock}
                  className="h-7 gap-1.5 text-muted-foreground"
                >
                  <Lock className="size-3.5" />
                  Locked
                </Button>
              ) : (
                <Button 
                  variant="ghost" 
                  size="sm" 
                  className="h-7 gap-1.5 text-muted-foreground/60"
                >
                  <Unlock className="size-3.5" />
                  Unlocked
                </Button>
              )}
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4 pt-0">
          {renderContent()}
        </CardContent>
      </Card>
    </NodeViewWrapper>
  );
}
