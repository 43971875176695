import { SignedIn, SignedOut, UserButton } from '@clerk/clerk-react';
import SignInButton from '@/components/sign-in-button';
import { cn } from '@/lib/utils';

function HeaderPublic() {
  return (
    <header className="sticky top-0 z-50 w-full bg-[#3b3b3b] text-white">
      <div className="container mx-auto flex h-20 items-center justify-between px-4">
        <div className="flex items-center">
          <a href="/" className="flex items-center space-x-2">
            <img
              src="/assets/ftld-logo.svg"
              alt="fTLD Logo"
              className="h-10 w-48 md:w-[180px]"
            />
          </a>
        </div>
        <nav className="flex items-center space-x-4 lg:space-x-6">
          <SignedIn>
            <a
              href="/app/dashboard"
              className={cn(
                'flex items-center text-[#ffc745]',
                'text-sm font-medium',
              )}
            >
              <span className="material-icons mr-2 text-[18px]">dashboard</span>
              Dashboard
            </a>
          </SignedIn>
          <SignedOut>
            <SignInButton />
          </SignedOut>
          <SignedIn>
            <UserButton />
          </SignedIn>
        </nav>
      </div>
    </header>
  );
}

export default HeaderPublic;
