import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { ClerkProvider } from '@clerk/clerk-react';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import HelmetHeader from '@/components/helmet-header';
import { Toaster } from '@/components/ui/sonner';
import { theme } from '@/config/theme';
import { CLERK_PUBLISHABLE_KEY } from '@/lib/globals';
import { TooltipProvider } from './components/ui/tooltip';
import router from './routes';

// Create a react query client
const queryClient = new QueryClient();

export default function App() {
  return (
    <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY} afterSignOutUrl="/">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <TooltipProvider>
            <HelmetProvider>
              <HelmetHeader />
              <RouterProvider router={router} />
              <Toaster />
            </HelmetProvider>
          </TooltipProvider>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ClerkProvider>
  );
}
