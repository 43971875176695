import React, { useState, useEffect, useMemo } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';

interface Task {
  id: string;
  title: string;
  status: string;
  priority: string;
  dueDate: string;
}

interface DataGridProps {
  data: Task[];
}

const DataGrid: React.FC<DataGridProps> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    id: '',
    title: '',
    status: '',
    priority: '',
    dueDate: '',
  });

  const itemsPerPage = 5;

  const filteredData = useMemo(() => {
    return data.filter((task) =>
      Object.entries(filters).every(([key, value]) => {
        if (!value)
          return true;
        const taskValue = task[key as keyof Task].toLowerCase();
        return taskValue.includes(value.toLowerCase());
      }),
    );
  }, [data, filters]);

  const totalPages = Math.max(1, Math.ceil(filteredData.length / itemsPerPage));
  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  const handleFilterChange = (key: keyof typeof filters) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prev) => ({ ...prev, [key]: e.target.value }));
    setCurrentPage(1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  return (
    <div>
      <div className="mb-4 grid grid-cols-5 gap-4">
        {Object.keys(filters).map((key) => (
          <Input
            key={key}
            type="text"
            placeholder={`Filter ${key.charAt(0).toUpperCase() + key.slice(1)}`}
            value={filters[key as keyof typeof filters]}
            onChange={handleFilterChange(key as keyof typeof filters)}
          />
        ))}
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>ID</TableHead>
            <TableHead>Title</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Priority</TableHead>
            <TableHead>Due Date</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {visibleData.map((task) => (
            <TableRow key={task.id}>
              <TableCell>{task.id}</TableCell>
              <TableCell>{task.title}</TableCell>
              <TableCell>{task.status}</TableCell>
              <TableCell>{task.priority}</TableCell>
              <TableCell>{task.dueDate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="mt-4 flex justify-between">
        <Button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span>
          Page
          {currentPage}
          {' '}
          of
          {totalPages}
        </span>
        <Button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default DataGrid;
