import { useState } from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import type { Transaction } from '@tiptap/pm/state';
import { CheckCircle2, Lock, Search, Unlock, Building2, ArrowLeft, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { useRegulatoryLinks } from '@/hooks/use-regulatory-links';

type VerificationMode = 'search' | 'results' | 'verify';

export function RegulatoryAuthorityLookupComponent({ node, updateAttributes, editor, getPos }: any) {
  const [mode, setMode] = useState<VerificationMode>(node.attrs.mode || 'search');
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState('');
  const [purpose, setPurpose] = useState('');

  const { data: regulatoryLinks } = useRegulatoryLinks();

  const handleVerify = () => {
    updateAttributes({
      verified: true,
      verifiedBy: 'Current User',
      verificationDate: new Date().toISOString(),
    });
  };

  const handleUnlock = () => {
    updateAttributes({
      verified: false,
      verifiedBy: null,
      verificationDate: null,
      mode: 'search',
    });
    setMode('search');
  };

  const handleDelete = () => {
    if (!editor || typeof getPos !== 'function') return;
    
    const pos = getPos();
    editor.chain()
      .focus()
      .command(({ tr }: { tr: Transaction }) => {
        tr.delete(pos, pos + node.nodeSize);
        return true;
      })
      .run();
  };

  const handleSearch = () => {
    if (!country && !purpose) return;

    const results = regulatoryLinks?.filter(link => 
      (!country || link.country.toLowerCase().includes(country.toLowerCase())) &&
      (!purpose || link.purpose.toLowerCase().includes(purpose.toLowerCase()))
    );

    setMode('results');
    updateAttributes({
      lookupDate: new Date().toISOString(),
      databaseResults: results,
      lookupResult: `Found ${results?.length || 0} matching regulatory databases`,
      country,
      purpose,
    });
  };

  const renderContent = () => {
    if (node.attrs.verified) {
      return (
        <div className="space-y-4">
          <div className="grid gap-3">
            {node.attrs.country && (
              <div>
                <Label className="text-xs font-normal text-muted-foreground">Country</Label>
                <div className="mt-1.5 text-sm">{node.attrs.country}</div>
              </div>
            )}
            {node.attrs.purpose && (
              <div>
                <Label className="text-xs font-normal text-muted-foreground">Purpose</Label>
                <div className="mt-1.5 text-sm">{node.attrs.purpose}</div>
              </div>
            )}
            {node.attrs.lookupResult && (
              <div>
                <Label className="text-xs font-normal text-muted-foreground">Lookup Result</Label>
                <div className="mt-1.5 text-sm">{node.attrs.lookupResult}</div>
              </div>
            )}
            {node.attrs.databaseResults && node.attrs.databaseResults.length > 0 && (
              <div>
                <Label className="text-xs font-normal text-muted-foreground">Database Results</Label>
                <div className="mt-1.5 space-y-2">
                  {node.attrs.databaseResults.map((result: any, index: number) => (
                    <div key={index} className="rounded-md bg-muted p-2 text-sm">
                      <div><strong>Country:</strong> {result.country}</div>
                      <div><strong>Purpose:</strong> {result.purpose}</div>
                      <div><strong>Notes:</strong> {result.notes}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <Separator />
          <div className="grid gap-1.5 text-xs text-muted-foreground">
            <div className="flex items-center justify-between">
              <span>Verified by</span>
              <span className="font-medium text-foreground">{node.attrs.verifiedBy}</span>
            </div>
            <div className="flex items-center justify-between">
              <span>Verification date</span>
              <span className="font-medium text-foreground">
                {new Date(node.attrs.verificationDate).toLocaleString()}
              </span>
            </div>
          </div>
        </div>
      );
    }

    if (mode === 'search') {
      return (
        <div className="space-y-4">
          <div>
            <Label className="text-xs font-normal text-muted-foreground">Country</Label>
            <Input
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder="Enter country"
              className="mt-1.5"
            />
          </div>
          <div>
            <Label className="text-xs font-normal text-muted-foreground">Purpose</Label>
            <Input
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
              placeholder="Enter purpose"
              className="mt-1.5"
            />
          </div>
          <Button
            onClick={handleSearch}
            disabled={!country && !purpose}
            className="w-full"
          >
            Search Regulatory Databases
          </Button>
        </div>
      );
    }

    if (mode === 'results') {
      return (
        <div className="space-y-4">
          <div className="rounded-md bg-muted p-3 text-sm">
            <p className="font-medium">Search Results</p>
            <p className="mt-1 text-muted-foreground">{node.attrs.lookupResult}</p>
            <p className="mt-1 text-xs text-muted-foreground">
              Searched on: {new Date(node.attrs.lookupDate).toLocaleString()}
            </p>
          </div>
          {node.attrs.databaseResults && node.attrs.databaseResults.length > 0 && (
            <div className="space-y-2">
              {node.attrs.databaseResults.map((result: any, index: number) => (
                <div key={index} className="rounded-md bg-muted p-2 text-sm">
                  <div><strong>Country:</strong> {result.country}</div>
                  <div><strong>Purpose:</strong> {result.purpose}</div>
                  <div><strong>Notes:</strong> {result.notes}</div>
                </div>
              ))}
            </div>
          )}
          <Button onClick={handleVerify} className="w-full gap-2">
            <CheckCircle2 className="size-4" />
            Sign off verification
          </Button>
        </div>
      );
    }
  };

  // Check if cursor is right before this node
  const isBeforeCursor = editor?.state.selection.$from.pos === getPos();

  return (
    <NodeViewWrapper>
      <Card className={cn(
        "border-muted bg-muted/30 transition-all duration-150",
        isBeforeCursor && !node.attrs.verified && "border-[#739c78]/40 bg-[#eef3ef] ring-2 ring-[#739c78]/40"
      )}>
        <CardHeader className="px-4 py-2">
          <CardTitle className="flex items-center justify-between text-base">
            <div className="flex items-center gap-2">
              <Building2 className="size-4" />
              Regulatory Authority Verification
            </div>
            <div className="flex items-center gap-1">
              {!node.attrs.verified && (
                <Button 
                  variant="ghost" 
                  size="sm"
                  onClick={handleDelete}
                  className="h-7 px-2 text-muted-foreground hover:text-destructive"
                >
                  <Trash2 className="size-3.5" />
                </Button>
              )}
              {node.attrs.verified ? (
                <Button 
                  variant="ghost" 
                  size="sm" 
                  onClick={handleUnlock}
                  className="h-7 gap-1.5 text-muted-foreground"
                >
                  <Lock className="size-3.5" />
                  Locked
                </Button>
              ) : (
                <Button 
                  variant="ghost" 
                  size="sm" 
                  className="h-7 gap-1.5 text-muted-foreground/60"
                >
                  <Unlock className="size-3.5" />
                  Unlocked
                </Button>
              )}
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4 pt-0">
          {renderContent()}
        </CardContent>
      </Card>
    </NodeViewWrapper>
  );
}
