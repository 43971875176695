import { useClerk } from '@clerk/clerk-react';
import { cn } from '@/lib/utils';

function SignInButton() {
  const { openSignIn } = useClerk();

  return (
    <button
      type="button"
      onClick={() => openSignIn()}
      className={cn(
        'flex items-center text-[#ffc745]',
        'text-sm font-medium',
      )}
    >
      <span className="material-icons mr-2 text-[18px]">login</span>
      Sign In
    </button>
  );
};

export default SignInButton;
