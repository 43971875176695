import { createContext, useContext, useRef, useMemo, type ReactNode } from 'react';
import type { GridApi } from '@ag-grid-community/core';

interface GridContextType {
  registerGridApi: (api: GridApi) => void;
  clearGridSelection: () => void;
}

const GridContext = createContext<GridContextType | null>(null);

export function GridProvider({ children }: { children: ReactNode }) {
  const gridApiRef = useRef<GridApi | null>(null);

  const value = useMemo(() => ({
    registerGridApi: (api: GridApi) => {
      gridApiRef.current = api;
    },
    clearGridSelection: () => {
      if (gridApiRef.current) {
        gridApiRef.current.deselectAll();
      }
    },
  }), []);

  return (
    <GridContext.Provider value={value}>
      {children}
    </GridContext.Provider>
  );
}

export function useGrid() {
  const context = useContext(GridContext);
  if (!context) {
    throw new Error('useGrid must be used within a GridProvider');
  }
  return context;
}
