import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

type Props = {
  title: string;
  statistics: Record<string, number | string>;
};

function formatStatisticKey(key: string): string {
  if (key.includes(' ')) {
    return key;
  }

  return key
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

function StatisticsCardColumn({ title, statistics }: Props) {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg font-semibold">{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {Object.entries(statistics).map(([key, value]) => (
            <li className="flex justify-between" key={key}>
              <span className="text-sm text-muted-foreground">{formatStatisticKey(key)}</span>
              <span className="font-medium">{value}</span>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
}

export default StatisticsCardColumn;
