import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import type { Content, Editor } from '@tiptap/react';
import FormInput from '@/components/form-components/form-input';
import { TiptapEditor } from '@/components/tiptap';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { Textarea } from '@/components/ui/textarea';
import type { EmailTemplate } from '@/hooks/use-email-templates';

const extensions = [
  Document,
  Paragraph,
  Text,
  Heading.configure({
    levels: [1, 2, 3, 4],
  }),
  HorizontalRule,
  Bold,
  Italic,
  Strike,
  Code,
  CodeBlock,
  BulletList,
  OrderedList,
  ListItem,
  Blockquote,
  HardBreak,
  History,
  Underline,
  Link.configure({
    openOnClick: false,
  }),
];

const emptyContent: Content = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [{ type: 'text', text: '' }],
    },
  ],
};

type FormValues = {
  name: string;
  short_description: string;
  long_description: string;
  tld: string;
  subject: string;
};

type TemplateSheetProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  initialData?: EmailTemplate;
  onSubmit: (data: {
    name: string;
    short_description: string;
    long_description: string;
    tld: string;
    template: { body: string };
    subject: string;
  }) => Promise<void>;
};

function TemplateSheet({ open, onOpenChange, initialData, onSubmit }: TemplateSheetProps) {
  const form = useForm<FormValues>({
    defaultValues: {
      name: '',
      short_description: '',
      long_description: '',
      tld: '',
      subject: '',
    },
  });

  const [editor, setEditor] = useState<Editor | null>(null);
  const [content, setContent] = useState<Content>(emptyContent);

  // Update form values and editor content when initialData changes
  useEffect(() => {
    if (initialData) {
      form.reset({
        name: initialData.name,
        short_description: initialData.short_description,
        long_description: initialData.long_description,
        tld: initialData.tld,
        subject: initialData.subject,
      });

      try {
        const parsedContent = JSON.parse(initialData.template.body);
        setContent(parsedContent);
        if (editor) {
          editor.commands.setContent(parsedContent);
        }
      } catch {
        const fallbackContent = {
          type: 'doc',
          content: [
            {
              type: 'paragraph',
              content: [{ type: 'text', text: initialData.template.body }],
            },
          ],
        };
        setContent(fallbackContent);
        if (editor) {
          editor.commands.setContent(fallbackContent);
        }
      }
    } else {
      form.reset({
        name: '',
        short_description: '',
        long_description: '',
        tld: '',
        subject: '',
      });
      setContent(emptyContent);
      if (editor) {
        editor.commands.setContent(emptyContent);
      }
    }
  }, [initialData, form.reset, editor]);

  const handleFormSubmit = async (data: FormValues) => {
    await onSubmit({
      ...data,
      template: {
        body: JSON.stringify(content),
      },
    });
    form.reset();
    setContent(emptyContent);
    if (editor) {
      editor.commands.setContent(emptyContent);
    }
    onOpenChange(false);
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side="right"
        className="w-full max-w-full sm:max-w-2xl"
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <SheetHeader>
          <SheetTitle>{initialData ? 'Edit Template' : 'Create Template'}</SheetTitle>
        </SheetHeader>
        <form onSubmit={form.handleSubmit(handleFormSubmit)} className="h-[calc(100vh-8rem)] space-y-6 overflow-y-auto px-1 pt-8">
          <FormInput
            control={form.control}
            name="name"
            label="Name"
            required
            rules={{ required: 'Name is required' }}
            placeholder="e.g., welcome_email"
          />

          <FormInput
            control={form.control}
            name="subject"
            label="Subject"
            required
            rules={{ required: 'Subject is required' }}
            placeholder="e.g., Welcome to our platform"
          />

          <div className="space-y-2">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              Short Description
            </label>
            <Textarea
              {...form.register('short_description', { required: 'Short description is required' })}
              className="min-h-[60px] resize-y"
              placeholder="Brief description of the template"
            />
            {form.formState.errors.short_description && (
              <p className="text-sm text-destructive">{form.formState.errors.short_description.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              Long Description
            </label>
            <Textarea
              {...form.register('long_description')}
              className="min-h-[100px] resize-y"
              placeholder="Detailed description of the template and its usage"
            />
          </div>

          <FormInput
            control={form.control}
            name="tld"
            label="TLD"
            required
            rules={{ required: 'TLD is required' }}
            placeholder="BANK/INSURANCE"
          />

          <div className="space-y-2">
            <label className="text-sm font-medium leading-none">
              Template Body
              <span className="ml-1 text-xs text-muted-foreground">
                (Use
                {' '}
                {'{{'}
                {' '}
                and
                {' '}
                {'}}'}
                {' '}
                for template variables, e.g.
                {' '}
                {'{{name}}'}
                )
              </span>
            </label>
            <div className="min-h-[300px] rounded-md border">
              <TiptapEditor
                value={content}
                onChange={setContent}
                onCreate={({ editor }) => {
                  setEditor(editor);
                  if (initialData) {
                    try {
                      const parsedContent = JSON.parse(initialData.template.body);
                      editor.commands.setContent(parsedContent);
                    } catch {
                      editor.commands.setContent({
                        type: 'doc',
                        content: [
                          {
                            type: 'paragraph',
                            content: [{ type: 'text', text: initialData.template.body }],
                          },
                        ],
                      });
                    }
                  }
                }}
                extensions={extensions}
                className="min-h-[300px]"
                editorContentClassName="!h-full flex flex-col [&_.ProseMirror]:flex-1 [&_.ProseMirror]:overflow-y-auto [&_.ProseMirror]:pb-36 [&_.ProseMirror]:px-8 [&_.ProseMirror]:pt-4"
                editorProps={{
                  attributes: {
                    class: 'h-full prose prose-sm prose-stone dark:prose-invert',
                  },
                }}
                hideSections={['tasks']}
              />
            </div>
          </div>

          <div className="pt-4">
            <Button type="submit" className="w-full" disabled={form.formState.isSubmitting}>
              {initialData ? 'Update Template' : 'Create Template'}
            </Button>
          </div>
        </form>
      </SheetContent>
    </Sheet>
  );
}

export default TemplateSheet;
