import { Globe, Calendar, CheckCircle2, XCircle } from 'lucide-react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { formatDate } from '@/lib/date';

interface RDDSData {
  domainName: string;
  registrar: string;
  whoisServer: string;
  referralUrl: string;
  updatedDate: string;
  creationDate: string;
  expiryDate: string;
  status: string[];
  dnssec: boolean;
  nameServers: string[];
}

interface DomainInfoProps {
  data: RDDSData;
}

function DomainInfo({ data }: DomainInfoProps) {
  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2 text-lg">
            <Globe className="size-5" />
            Domain Information
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid gap-4 md:grid-cols-2">
            <div>
              <div className="text-sm font-medium text-muted-foreground">Domain Name</div>
              <div>{data.domainName}</div>
            </div>
            <div>
              <div className="text-sm font-medium text-muted-foreground">Registrar</div>
              <div>{data.registrar}</div>
            </div>
            <div>
              <div className="text-sm font-medium text-muted-foreground">WHOIS Server</div>
              <div>{data.whoisServer}</div>
            </div>
            <div>
              <div className="text-sm font-medium text-muted-foreground">Referral URL</div>
              <a
                href={data.referralUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:underline"
              >
                {data.referralUrl}
              </a>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2 text-lg">
            <Calendar className="size-5" />
            Important Dates
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid gap-4 md:grid-cols-3">
            <div>
              <div className="text-sm font-medium text-muted-foreground">Created</div>
              <div>{formatDate(data.creationDate)}</div>
            </div>
            <div>
              <div className="text-sm font-medium text-muted-foreground">Updated</div>
              <div>{formatDate(data.updatedDate)}</div>
            </div>
            <div>
              <div className="text-sm font-medium text-muted-foreground">Expires</div>
              <div>{formatDate(data.expiryDate)}</div>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2 text-lg">
            {data.dnssec
              ? (
                  <CheckCircle2 className="size-5 text-green-500" />
                )
              : (
                  <XCircle className="size-5 text-red-500" />
                )}
            DNSSEC & Name Servers
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div>
            <div className="text-sm font-medium text-muted-foreground">DNSSEC</div>
            <div className={data.dnssec ? 'text-green-600' : 'text-red-600'}>
              {data.dnssec ? 'Signed' : 'Unsigned'}
            </div>
          </div>
          <div>
            <div className="text-sm font-medium text-muted-foreground">Name Servers</div>
            <ul className="mt-1 space-y-1">
              {data.nameServers.map((ns, index) => (
                <li key={index}>{ns}</li>
              ))}
            </ul>
          </div>
          <div>
            <div className="text-sm font-medium text-muted-foreground">Domain Status</div>
            <ul className="mt-1 space-y-1">
              {data.status.map((status, index) => (
                <li key={index} className="text-sm">
                  {status}
                </li>
              ))}
            </ul>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

interface RDDSPanelProps {
  dealId?: string;
}

export function RDDSPanel({ dealId }: RDDSPanelProps) {
  // This would typically fetch data from an API
  const domainsData: RDDSData[] = [
    {
      domainName: 'lloyds.bank',
      registrar: 'CSC Corporate Domains',
      whoisServer: 'whois.nic.bank',
      referralUrl: 'http://www.cscglobal.com',
      updatedDate: '2024-01-15T10:30:00Z',
      creationDate: '2015-05-15T08:00:00Z',
      expiryDate: '2025-05-15T08:00:00Z',
      status: [
        'clientTransferProhibited',
        'clientUpdateProhibited',
        'clientDeleteProhibited',
        'serverTransferProhibited',
      ],
      dnssec: true,
      nameServers: [
        'ns1.cscglobal.net',
        'ns2.cscglobal.net',
        'ns3.cscglobal.net',
      ],
    },
    {
      domainName: 'lloyds.insurance',
      registrar: 'CSC Corporate Domains',
      whoisServer: 'whois.nic.insurance',
      referralUrl: 'http://www.cscglobal.com',
      updatedDate: '2024-01-15T10:30:00Z',
      creationDate: '2015-06-15T08:00:00Z',
      expiryDate: '2025-06-15T08:00:00Z',
      status: [
        'clientTransferProhibited',
        'clientUpdateProhibited',
        'clientDeleteProhibited',
        'serverTransferProhibited',
      ],
      dnssec: true,
      nameServers: [
        'ns1.cscglobal.net',
        'ns2.cscglobal.net',
        'ns3.cscglobal.net',
      ],
    },
  ];

  return (
    <ScrollArea className="h-full">
      <div className="space-y-4 p-4">
        <Accordion type="single" collapsible className="w-full">
          {domainsData.map((domain, index) => (
            <AccordionItem key={domain.domainName} value={`domain-${index}`}>
              <AccordionTrigger className="text-base font-semibold">
                {domain.domainName}
              </AccordionTrigger>
              <AccordionContent>
                <div className="pt-2">
                  <DomainInfo data={domain} />
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </ScrollArea>
  );
}
