import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import { CircularProgress } from '@mui/material';

function Logout() {
  const navigate = useNavigate();
  const { isLoaded, isSignedIn, signOut } = useAuth();

  useEffect(() => {
    const handleSignOut = async () => {
      if (isSignedIn) {
        try {
          await signOut({ redirectUrl: '/' });
        } catch (error) {
          console.error('Sign out failed:', error);
          navigate('/');
        }
      } else {
        navigate('/');
      }
    };

    void handleSignOut();
  }, [isSignedIn, signOut, navigate]);
  if (!isSignedIn) {
    return (
      <div className="flex h-svh flex-col items-center justify-center space-y-4">
        <h2 className="text-2xl font-medium">You are now logged out</h2>
        <p>Redirecting...</p>
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div className="flex h-svh flex-col items-center justify-center space-y-4">
        <h2 className="text-2xl font-medium">Logging out, please wait...</h2>
        <CircularProgress />
      </div>
    );
  }

  return null;
}

export default Logout;
