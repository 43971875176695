import React from 'react';
import type { Control, FieldValues, Path, PathValue, RegisterOptions } from 'react-hook-form';
import { useController } from 'react-hook-form';
import type { InputProps } from '@/components/ui/input';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { cn } from '@/lib/utils';

export type FormInputProps<TFieldValues extends FieldValues> = Omit<InputProps, 'name' | 'defaultValue'> & {
  id?: string;
  name: Path<TFieldValues>;
  label?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  autoComplete?: string;
  fullWidth?: boolean;
  autoFocus?: boolean;
  control?: Control<TFieldValues>;
  rules?: Omit<RegisterOptions<TFieldValues, Path<TFieldValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  defaultValue?: PathValue<TFieldValues, Path<TFieldValues>>;
};

const FormInput = React.forwardRef(<TFieldValues extends FieldValues>(
  {
    className,
    label,
    error,
    helperText,
    placeholder,
    defaultValue,
    name,
    autoComplete = 'off',
    autoFocus = false,
    fullWidth = false,
    id,
    required = false,
    type = 'text',
    control,
    rules,
    startAdornment,
    endAdornment,
    ...props
  }: FormInputProps<TFieldValues>,
  ref: React.ForwardedRef<HTMLInputElement>,
) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const fieldError = fieldState.error;
  const isError = error || !!fieldError;

  const inputClassName = cn(
    className,
    isError && 'border-red-500 focus-visible:ring-red-500',
    fullWidth && 'w-full',
    startAdornment && 'pl-10',
    endAdornment && 'pr-10',
  );

  const labelClassName = cn(
    'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
    isError && 'text-red-500',
  );

  const helperTextClassName = cn(
    'text-xs mt-1',
    isError ? 'text-red-500' : 'text-gray-500',
  );

  const adornmentClassName = 'absolute top-1/2 transform -translate-y-1/2 text-gray-500';

  return (
    <div className={cn('grid w-full gap-1.5', fullWidth && 'w-full')}>
      {label && (
        <Label
          htmlFor={id}
          className={labelClassName}
        >
          {label}
          {required && <span className="ml-1 text-red-500">*</span>}
        </Label>
      )}
      <div className="relative">
        {startAdornment && (
          <div className={cn(adornmentClassName, 'left-3')}>
            {startAdornment}
          </div>
        )}
        <Input
          id={id}
          type={type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={inputClassName}
          aria-describedby={helperText ? `${id}-helper-text` : undefined}
          {...props}
          {...field}
          ref={ref}
        />
        {endAdornment && (
          <div className={cn(adornmentClassName, 'right-3')}>
            {endAdornment}
          </div>
        )}
      </div>
      {(fieldError?.message || helperText) && (
        <p
          id={`${id}-helper-text`}
          className={helperTextClassName}
        >
          {fieldError?.message || helperText}
        </p>
      )}
    </div>
  );
}) as <TFieldValues extends FieldValues>(
  props: FormInputProps<TFieldValues> & { ref?: React.ForwardedRef<HTMLInputElement> }
) => React.ReactElement;

export default FormInput;
