function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="z-20 bg-[#3b3b3b] text-white">
      <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-sm leading-5 text-white">
            ©
            {' '}
            {currentYear}
            {' '}
            fTLD Registry Services, LLC All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
