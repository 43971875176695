import * as React from 'react';
import { cn } from '@/lib/utils';

interface TabItem {
  value: string;
  label: string;
}

interface DealTabsProps {
  tabs: TabItem[];
  value: string;
  onValueChange: (value: string) => void;
}

export function DealTabs({ tabs, value, onValueChange }: DealTabsProps) {
  // Split tabs into two rows
  const topRow = tabs.slice(0, 3);
  const bottomRow = tabs.slice(3);

  return (
    <div className="rounded-lg bg-muted/30 p-1">
      <div className="grid grid-cols-1 gap-1">
        <div className="grid grid-cols-3 gap-1">
          {topRow.map((tab) => (
            <button
              type="button"
              key={tab.value}
              onClick={() => onValueChange(tab.value)}
              className={cn(
                'rounded-md px-3 py-2 text-sm font-medium transition-all',
                'hover:bg-background/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                'disabled:pointer-events-none disabled:opacity-50',
                value === tab.value
                  ? 'bg-background text-foreground shadow-sm'
                  : 'text-muted-foreground',
              )}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-3 gap-1">
          {bottomRow.map((tab) => (
            <button
              type="button"
              key={tab.value}
              onClick={() => onValueChange(tab.value)}
              className={cn(
                'rounded-md px-3 py-2 text-sm font-medium transition-all',
                'hover:bg-background/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                'disabled:pointer-events-none disabled:opacity-50',
                value === tab.value
                  ? 'bg-background text-foreground shadow-sm'
                  : 'text-muted-foreground',
              )}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

interface DealTabContentProps {
  value: string;
  activeValue: string;
  children: React.ReactNode;
}

export function DealTabContent({ value, activeValue, children }: DealTabContentProps) {
  if (value !== activeValue)
    return null;

  return (
    <div className="mt-4 h-[calc(100%-5rem)]">
      {children}
    </div>
  );
}
