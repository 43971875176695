import { FileText, Upload, Download, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';

interface FilesPanelProps {
  dealId?: string;
}

interface FileData {
  id: string;
  name: string;
  type: string;
  size: number;
  uploadedBy: string;
  uploadedAt: string;
  category: 'verification' | 'documentation' | 'communication' | 'other';
}

export function FilesPanel({ dealId }: FilesPanelProps) {
  // This would typically fetch data from an API
  const files: FileData[] = [
    {
      id: '1',
      name: 'business-license.pdf',
      type: 'application/pdf',
      size: 2.5 * 1024 * 1024, // 2.5MB
      uploadedBy: 'John Smith',
      uploadedAt: '2024-01-15T10:30:00Z',
      category: 'verification',
    },
    {
      id: '2',
      name: 'domain-authorization.docx',
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      size: 1.8 * 1024 * 1024, // 1.8MB
      uploadedBy: 'Sarah Johnson',
      uploadedAt: '2024-01-14T15:45:00Z',
      category: 'documentation',
    },
    {
      id: '3',
      name: 'verification-checklist.xlsx',
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      size: 500 * 1024, // 500KB
      uploadedBy: 'Mike Wilson',
      uploadedAt: '2024-01-13T09:15:00Z',
      category: 'verification',
    },
  ];

  const formatFileSize = (bytes: number): string => {
    const units = ['B', 'KB', 'MB', 'GB'];
    let size = bytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(1)} ${units[unitIndex]}`;
  };

  const getFileIcon = (type: string) => {
    if (type.includes('pdf'))
      return '📄';
    if (type.includes('word'))
      return '📝';
    if (type.includes('sheet'))
      return '📊';
    if (type.includes('image'))
      return '🖼️';
    return '📎';
  };

  const handleUpload = () => {
    // Implement file upload logic
    console.log('Upload file');
  };

  const handleDownload = (fileId: string) => {
    // Implement file download logic
    console.log('Download file:', fileId);
  };

  const handleDelete = (fileId: string) => {
    // Implement file deletion logic
    console.log('Delete file:', fileId);
  };

  return (
    <ScrollArea className="h-full">
      <div className="space-y-4 p-4">
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold">Files & Documents</h2>
          <Button onClick={handleUpload}>
            <Upload className="mr-2 size-4" />
            Upload File
          </Button>
        </div>

        <div className="grid gap-4">
          {['verification', 'documentation', 'communication', 'other'].map((category) => {
            const categoryFiles = files.filter((f) => f.category === category);
            if (categoryFiles.length === 0)
              return null;

            return (
              <Card key={category}>
                <CardHeader>
                  <CardTitle className="flex items-center gap-2 text-lg">
                    <FileText className="size-5" />
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                    {' '}
                    Files
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-2">
                    {categoryFiles.map((file) => (
                      <div key={file.id}>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <span className="text-xl">{getFileIcon(file.type)}</span>
                            <div>
                              <div className="font-medium">{file.name}</div>
                              <div className="text-sm text-muted-foreground">
                                {formatFileSize(file.size)}
                                {' '}
                                • Uploaded by
                                {file.uploadedBy}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-2">
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => handleDownload(file.id)}
                            >
                              <Download className="size-4" />
                            </Button>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => handleDelete(file.id)}
                            >
                              <Trash2 className="size-4" />
                            </Button>
                          </div>
                        </div>
                        <Separator className="my-2" />
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            );
          })}
        </div>
      </div>
    </ScrollArea>
  );
}
