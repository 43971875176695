import { useState } from 'react';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { companyColumnDefs, sampleContactData } from './components/company-grid-columns';
import CreateCompanySheet from './components/create-company-sheet';

function ContactsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  return (
    <Container className="mt-8 pb-12">
      <div className="mb-3 flex justify-between">
        <div>
          <h1 className="text-xl font-bold">Contacts</h1>
        </div>
        <div>
          <Button onClick={() => setIsCreateOpen(true)}>
            Create company
          </Button>
        </div>
      </div>
      <div className="mb-6">
        <Input
          type="search"
          placeholder="Search contacts..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-6">
        <AGDataGrid
          columnDefs={companyColumnDefs}
          rowData={sampleContactData}
          quickFilterText={searchQuery}
          rowSelection="multiple"
        />
      </div>

      <CreateCompanySheet
        open={isCreateOpen}
        onOpenChange={setIsCreateOpen}
      />
    </Container>
  );
}

export default ContactsPage;
