import * as React from 'react';
import type { Editor } from '@tiptap/react';
import { Plus } from 'lucide-react';
import { ToolbarButton } from '../toolbar-button';

interface CommandPaletteSectionProps {
  editor: Editor;
  onOpen: () => void;
}

export const CommandPaletteSection: React.FC<CommandPaletteSectionProps> = React.memo(
  ({ editor, onOpen }) => {
    return (
      <div className="flex items-center">
        <ToolbarButton
          tooltip="Add tools"
          onClick={onOpen}
          className="h-8 gap-1.5 px-2 text-xs"
        >
          <Plus className="h-4 w-4" />
          <span className="text-xs">⌘I</span>
        </ToolbarButton>
      </div>
    );
  }
);

CommandPaletteSection.displayName = 'CommandPaletteSection';

export default CommandPaletteSection;
