import type { FontWeightValue } from '@ag-grid-community/theming';
import { themeQuartz } from '@ag-grid-community/theming';

const fontWeight: FontWeightValue = 600;

export const agDataGridTheme = themeQuartz.withParams({
  spacing: 10,
  accentColor: 'var(--ftld-secondary)',
  headerFontWeight: fontWeight,
  headerCellHoverBackgroundColor: 'var(--ftld-secondary-50)',
  rowHoverColor: 'var(--ftld-secondary-100)',
});
