// src/pages/app/deals/index.tsx
import { useState } from 'react';
import { LayoutGrid, Table2 } from 'lucide-react';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { usePersistedState } from '@/hooks/use-persisted-state';
import { DealColumns } from './components/deal-columns';
import { dealColumnDefs, sampleDealData } from './components/deal-grid-columns';

type ViewMode = 'grid' | 'columns';

function DealsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [viewMode, setViewMode] = usePersistedState<ViewMode>('deals-view-mode', 'grid');

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-3 flex justify-between">
        <div>
          <h1 className="mt-2 text-xl font-bold">All Deals</h1>
        </div>
        <div className="flex items-center gap-4">
          <ToggleGroup
            type="single"
            value={viewMode}
            onValueChange={(value) => value && setViewMode(value as ViewMode)}
            aria-label="View mode"
          >
            <ToggleGroupItem
              value="grid"
              aria-label="Grid view"
              className="px-3"
            >
              <Table2 className="size-4" />
            </ToggleGroupItem>
            <ToggleGroupItem
              value="columns"
              aria-label="Column view"
              className="px-3"
            >
              <LayoutGrid className="size-4" />
            </ToggleGroupItem>
          </ToggleGroup>
          <Button onClick={() => console.log('Create deal')}>
            Create deal
          </Button>
        </div>
      </div>
      <div className="mb-6">
        <Input
          type="search"
          placeholder="Search deals..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      {viewMode === 'grid'
        ? (
            <AGDataGrid
              columnDefs={dealColumnDefs}
              rowData={sampleDealData}
              quickFilterText={searchQuery}
              rowSelection="multiple"
            />
          )
        : (
            <DealColumns deals={sampleDealData} />
          )}
    </Container>
  );
}

export default DealsPage;
