import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { useNylasGrants } from '@/hooks/use-nylas-grants';
import type { Provider } from 'nylas';

interface CreateConnectionFormData {
  provider: Provider;
}

type CreateConnectionSheetProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

function CreateConnectionSheet({ open, onOpenChange }: CreateConnectionSheetProps) {
  const { createGrant, isCreatingGrant } = useNylasGrants();
  const { handleSubmit, setValue } = useForm<CreateConnectionFormData>({
    defaultValues: {
      provider: 'google' as Provider,
    },
  });

  function onSubmit(data: CreateConnectionFormData) {
    createGrant({
      provider: data.provider,
    });
    onOpenChange(false);
  }

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side="right"
        className="w-full max-w-full sm:max-w-lg"
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <SheetHeader>
          <SheetTitle>Add New Connection</SheetTitle>
        </SheetHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 pt-8">
          <div className="space-y-2">
            <Label htmlFor="provider">Provider</Label>
            <Select
              defaultValue="google"
              onValueChange={(value: Provider) => setValue('provider', value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a provider" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="google">Google</SelectItem>
                <SelectItem value="imap">IMAP</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <Button
            type="submit"
            className="w-full"
            disabled={isCreatingGrant}
          >
            {isCreatingGrant ? 'Connecting...' : 'Connect Account'}
          </Button>
        </form>
      </SheetContent>
    </Sheet>
  );
}

export default CreateConnectionSheet;
