// src/components/tiptap/extensions/export-gov-lookup/index.ts
import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { toast } from 'sonner';

import { ExportGovLookupComponent } from './export-gov-lookup-component';

export interface ExportGovLookupOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    exportGovLookup: {
      setExportGovLookup: (attributes: { 
        entityName: string;
        entityType: string;
        mode?: 'choose' | 'api' | 'manual';
        verificationDetails?: string;
        lookupDate?: string;
        lookupResult?: string;
        verifiedBy?: string;
        verified?: boolean;
        verificationDate?: string;
      }) => ReturnType;
      deleteExportGovLookup: () => ReturnType;
    };
  }
}

export const ExportGovLookup = Node.create<ExportGovLookupOptions>({
  name: 'exportGovLookup',

  group: 'block',

  atom: true,

  selectable: true,

  draggable: true,

  addAttributes() {
    return {
      entityName: {
        default: null,
      },
      entityType: {
        default: null,
      },
      mode: {
        default: 'choose',
      },
      verificationDetails: {
        default: null,
      },
      lookupDate: {
        default: null,
      },
      lookupResult: {
        default: null,
      },
      verifiedBy: {
        default: null,
      },
      verified: {
        default: false,
      },
      verificationDate: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="export-gov-lookup"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'export-gov-lookup' })];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ExportGovLookupComponent);
  },

  addCommands() {
    return {
      setExportGovLookup:
        (attributes) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: attributes,
          });
        },
      deleteExportGovLookup:
        () =>
        ({ editor, state, dispatch }) => {
          const { selection } = state;
          const { $anchor } = selection;
          const pos = $anchor.pos;
          const node = state.doc.nodeAt(pos);

          if (!node || node.type.name !== this.name) {
            return false;
          }

          if (node.attrs.verified) {
            toast.error('Cannot delete a locked verification');
            return false;
          }

          if (dispatch) {
            const tr = state.tr;
            tr.delete(pos, pos + node.nodeSize);
            dispatch(tr);
          }

          return true;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Backspace': ({ editor }) => {
        const { empty, $anchor } = editor.state.selection;
        const pos = $anchor.pos;
        const node = editor.state.doc.nodeAt(pos);

        // Check if we're at the start of a paragraph before the component
        if (!node && pos > 0) {
          const nodeBefore = editor.state.doc.nodeAt(pos - 1);
          if (nodeBefore?.type.name === this.name && nodeBefore.attrs.verified) {
            toast.error('Cannot delete a locked verification');
            return true;
          }
        }

        // Check if we're inside or at the component
        if (node?.type.name === this.name && node.attrs.verified) {
          toast.error('Cannot delete a locked verification');
          return true;
        }

        return false;
      },
      'Delete': ({ editor }) => {
        const { empty, $anchor } = editor.state.selection;
        const pos = $anchor.pos;
        const node = editor.state.doc.nodeAt(pos);

        if (node?.type.name === this.name && node.attrs.verified) {
          toast.error('Cannot delete a locked verification');
          return true;
        }

        return false;
      },
      '/': () => {
        const { $head } = this.editor.state.selection;
        const textBefore = $head.parent.textContent.slice(0, $head.parentOffset);
        
        if (textBefore === '/') {
          // Delete the '/' character
          this.editor.commands.deleteRange({
            from: $head.pos - 1,
            to: $head.pos,
          });
          
          // Insert the lookup component
          return this.editor.commands.setExportGovLookup({
            entityName: '',
            entityType: '',
            mode: 'choose',
          });
        }
        return false;
      },
    };
  },
});
