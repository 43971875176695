import { useState, useMemo } from 'react';
import type { ColDef, ValueGetterParams } from '@ag-grid-community/core';
import { format } from 'date-fns';
import AGDataGrid from '@/components/ag-data-grid';
import { useNylasGrants } from '@/hooks/use-nylas-grants';
import { useNylasThreads, type Thread } from '@/hooks/use-nylas-threads';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';

function MessagesPage() {
  const [quickFilterText, setQuickFilterText] = useState('');
  const { data: grants } = useNylasGrants();
  const grantId = grants?.[0]?.id;

  const { data: threadsData, isLoading } = useNylasThreads({
    grantId: grantId || '',
    limit: 50,
  });

  const columnDefs = useMemo<ColDef<Thread>[]>(() => [
    {
      field: 'starred',
      headerName: '',
      width: 50,
      cellRenderer: (params: { value: boolean }) => 
        params.value ? '⭐' : '',
    },
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 2,
      cellRenderer: (params: { data: Thread }) => {
        const thread = params.data;
        return (
          <div className="flex flex-col">
            <span className={thread.unread ? 'font-bold' : ''}>
              {thread.subject}
            </span>
            <span className="text-sm text-gray-500 truncate">
              {thread.snippet}
            </span>
          </div>
        );
      },
    },
    {
      field: 'participants',
      headerName: 'From',
      flex: 1,
      valueGetter: (params: ValueGetterParams<Thread>) => {
        if (!params.data) return '';
        const sender = params.data.participants[0];
        return sender ? `${sender.name || sender.email}` : '';
      },
    },
    {
      field: 'hasAttachments',
      headerName: '',
      width: 50,
      cellRenderer: (params: { value: boolean }) => 
        params.value ? '📎' : '',
    },
    {
      field: 'lastMessageTimestamp',
      headerName: 'Date',
      width: 120,
      valueFormatter: (params: { value: number }) => 
        format(params.value * 1000, 'MMM d, yyyy'),
    },
  ], []);

  if (!grantId) {
    return (
      <Card className="p-4">
        <p>Please connect your email account in Settings to view messages.</p>
      </Card>
    );
  }

  return (
    <div className="p-4 space-y-4">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Messages</h1>
        <Input
          type="text"
          placeholder="Search messages..."
          className="w-64"
          value={quickFilterText}
          onChange={(e) => setQuickFilterText(e.target.value)}
        />
      </div>

      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AGDataGrid
          rowData={threadsData?.threads || []}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
          quickFilterText={quickFilterText}
          loadingOverlayComponent={() => 'Loading messages...'}
          loading={isLoading}
          rowSelection="single"
          enableCellTextSelection
        />
      </div>
    </div>
  );
}

export default MessagesPage;
