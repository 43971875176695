import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import type { Content, Editor } from '@tiptap/react';
import { Search, Database } from 'lucide-react';
import { useBreadcrumb } from '@/components/breadcrumb-provider';
import Container from '@/components/container';
import { TiptapEditor } from '@/components/tiptap';
import { ExportGovLookup } from '@/components/tiptap/extensions/export-gov-lookup';
import { RegulatoryAuthorityLookup } from '@/components/tiptap/extensions/regulatory-authority-lookup';
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import type { Deal, DealStage } from '@/types/deal';
import {
  ActivityPanel,
  CommunicationsPanel,
  ContactsPanel,
  FilesPanel,
  RDDSPanel,
  RegistrantOrgPanel,
} from './components';
import { DealTabs, DealTabContent } from './components/deal-tabs';

const extensions = [
  Document,
  Paragraph,
  Text,
  Heading.configure({
    levels: [1, 2, 3, 4],
  }),
  HorizontalRule,
  Bold,
  Italic,
  Strike,
  Code,
  CodeBlock,
  BulletList,
  OrderedList,
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  ListItem,
  Blockquote,
  HardBreak,
  History,
  Underline,
  Link.configure({
    openOnClick: false,
  }),
  ExportGovLookup.configure({
    HTMLAttributes: {
      class: 'my-4',
    },
  }),
  RegulatoryAuthorityLookup.configure({
    HTMLAttributes: {
      class: 'my-4',
    },
  }),
];

const initialContent: Content = {
  type: 'doc',
  content: [
    {
      type: 'heading',
      attrs: { level: 2 },
      content: [{ type: 'text', text: 'Verification Checklist' }],
    },
    {
      type: 'taskList',
      content: [
        {
          type: 'taskItem',
          attrs: { checked: false },
          content: [{ type: 'text', text: 'Security Check - ExportGov Results' }],
        },
        {
          type: 'taskItem',
          attrs: { checked: false },
          content: [{ type: 'text', text: 'Org Eligibility - Regulatory Authority Results' }],
        },
        {
          type: 'taskItem',
          attrs: { checked: false },
          content: [{ type: 'text', text: 'Email Eligibility - Regulatory Authority Results' }],
        },
        {
          type: 'taskItem',
          attrs: { checked: false },
          content: [{ type: 'text', text: 'Org Address - Regulatory Authority Results' }],
        },
        {
          type: 'taskItem',
          attrs: { checked: false },
          content: [{ type: 'text', text: 'Name Selection' }],
        },
        {
          type: 'taskItem',
          attrs: { checked: false },
          content: [{ type: 'text', text: 'Trademark' }],
        },
        {
          type: 'taskItem',
          attrs: { checked: false },
          content: [{ type: 'text', text: 'Employment Authority' }],
        },
      ],
    },
    {
      type: 'heading',
      attrs: { level: 3 },
      content: [{ type: 'text', text: 'Security Check' }],
    },
    {
      type: 'exportGovLookup',
      attrs: {
        entityName: '',
        notes: '',
        verified: false,
      },
    },
    {
      type: 'heading',
      attrs: { level: 3 },
      content: [{ type: 'text', text: 'Regulatory Authority Verification' }],
    },
    {
      type: 'regulatoryAuthorityLookup',
      attrs: {
        entityName: '',
        verified: false,
      },
    },
  ],
};

const AVAILABLE_TOOLS = [
  {
    id: 'export-gov-lookup',
    name: 'Export.gov Lookup',
    description: 'Add an Export.gov verification component',
    icon: Search,
  },
  {
    id: 'regulatory-authority-lookup',
    name: 'Regulatory Authority Lookup',
    description: 'Add a regulatory authority verification component (includes FDIC)',
    icon: Database,
  },
];

const DEAL_TABS = [
  { value: 'registrant-org', label: 'Registrant Org' },
  { value: 'contacts', label: 'Contacts' },
  { value: 'rdds', label: 'RDDS' },
  { value: 'communications', label: 'Communications' },
  { value: 'files', label: 'Files' },
  { value: 'activity', label: 'Activity' },
];

const DEAL_STAGES: { value: DealStage; label: string }[] = [
  { value: 'Annual Verification', label: 'Annual Verification' },
  { value: 'Pending Verification', label: 'Pending Verification' },
  { value: 'Organization Verification in Progress', label: 'Organization Verification in Progress' },
  { value: 'Name Selection in Progress', label: 'Name Selection in Progress' },
  { value: 'Employment Verification in Progress', label: 'Employment Verification in Progress' },
  { value: 'WHOIS Update in Progress', label: 'WHOIS Update in Progress' },
  { value: 'Failed Verification', label: 'Failed Verification' },
  { value: 'Verification Completed', label: 'Verification Completed' },
  { value: 'Verified Data Sent', label: 'Verified Data Sent' },
];

function DealPage() {
  const { id } = useParams();
  const { setLabel, removeLabel } = useBreadcrumb();
  const [deal, setDeal] = useState<Deal>();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [editor, setEditor] = useState<Editor | null>(null);
  const [content, setContent] = useState<Content>(initialContent);
  const [activeTab, setActiveTab] = useState('registrant-org');
  const [stage, setStage] = useState<DealStage>('Annual Verification');

  useEffect(() => {
    // Fetch deal data
    // This would typically be an API call
    console.log('Fetching deal:', id);
  }, [id]);

  useEffect(() => {
    if (!deal)
      return;

    const path = `/app/deals/${id}`;
    setLabel(path, {
      name: deal.title,
      path,
    });
    return () => removeLabel(path);
  }, [id, deal, setLabel, removeLabel]);

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'i') {
      e.preventDefault();
      setToolsOpen(true);
    } else if ((e.metaKey || e.ctrlKey) && e.key === 's') {
      e.preventDefault();
      // Save deal content
      // This would typically be an API call
      console.log('Saving deal:', { id, content });
    }
  }, [id, content]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleSelect = useCallback((toolId: string) => {
    if (!editor)
      return;

    switch (toolId) {
      case 'export-gov-lookup': {
        editor.chain().focus().insertContent({
          type: 'exportGovLookup',
          attrs: {
            entityName: '',
            notes: '',
            verified: false,
          },
        }).run();
        break;
      }
      case 'regulatory-authority-lookup': {
        editor.chain().focus().insertContent({
          type: 'regulatoryAuthorityLookup',
          attrs: {
            entityName: '',
            verified: false,
          },
        }).run();
        break;
      }
    }

    setToolsOpen(false);
  }, [editor]);

  return (
    <>
      <CommandDialog open={toolsOpen} onOpenChange={setToolsOpen}>
        <CommandInput placeholder="Search tools..." />
        <CommandList>
          <CommandEmpty>No tools found.</CommandEmpty>
          <CommandGroup heading="Available Tools">
            {AVAILABLE_TOOLS.map((tool) => (
              <CommandItem
                key={tool.id}
                onSelect={() => handleSelect(tool.id)}
                className="flex items-center gap-2"
              >
                <tool.icon className="size-4" />
                <div>
                  <div className="font-medium">{tool.name}</div>
                  <div className="text-sm text-muted-foreground">{tool.description}</div>
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </CommandDialog>

      <Container maxWidth={false}>
        <div className="flex h-[calc(100vh-5rem)] gap-6">
          <div className="flex max-w-[587px] flex-1 flex-col rounded-lg border bg-card">
            <div className="flex-1 overflow-hidden">
              <TiptapEditor
                value={content}
                onChange={setContent}
                onCreate={({ editor }) => {
                  setEditor(editor);
                  editor.commands.setContent(initialContent);
                }}
                extensions={extensions}
                className="!h-full !min-h-full max-w-[585px]"
                editorContentClassName="!h-full flex flex-col [&_.ProseMirror]:flex-1 [&_.ProseMirror]:overflow-y-auto [&_.ProseMirror]:pb-36 [&_.ProseMirror]:px-8 [&_.ProseMirror]:pt-4"
                editorProps={{
                  attributes: {
                    class: 'h-full prose prose-sm prose-stone dark:prose-invert',
                  },
                }}
                onOpenCommandPalette={() => setToolsOpen(true)}
              />
            </div>
          </div>

          <div className="flex-1">
            <div className="h-full space-y-4">
              <div className="space-y-2 px-1">
                <Label className="">Deal Status</Label>
                <Select
                  value={stage}
                  onValueChange={(value: string) => setStage(value as DealStage)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select stage" />
                  </SelectTrigger>
                  <SelectContent>
                    {DEAL_STAGES.map((dealStage) => (
                      <SelectItem key={dealStage.value} value={dealStage.value}>
                        {dealStage.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <DealTabs
                tabs={DEAL_TABS}
                value={activeTab}
                onValueChange={setActiveTab}
              />

              <DealTabContent value="registrant-org" activeValue={activeTab}>
                <RegistrantOrgPanel dealId={id} />
              </DealTabContent>
              <DealTabContent value="contacts" activeValue={activeTab}>
                <ContactsPanel dealId={id} />
              </DealTabContent>
              <DealTabContent value="rdds" activeValue={activeTab}>
                <RDDSPanel dealId={id} />
              </DealTabContent>
              <DealTabContent value="communications" activeValue={activeTab}>
                <CommunicationsPanel dealId={id} />
              </DealTabContent>
              <DealTabContent value="files" activeValue={activeTab}>
                <FilesPanel dealId={id} />
              </DealTabContent>
              <DealTabContent value="activity" activeValue={activeTab}>
                <ActivityPanel dealId={id} />
              </DealTabContent>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default DealPage;
