import { SignUp } from '@clerk/clerk-react';

function SignupPage() {
  return (
    <main className="flex-1 grow">
      <div className="mt-4 flex justify-center lg:mt-8">
        <SignUp path="/signup" fallbackRedirectUrl="/app" />
      </div>
    </main>
  );
}

export default SignupPage;
