import ColumnStatistics from '@/components/card-statistics-column';
import Container from '@/components/container';
import DataGrid from '@/components/data-grid';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';

type Task = {
  id: string;
  title: string;
  status: string;
  priority: string;
  dueDate: string;
};

type StatisticsData = Record<string, number | string>;

function Dashboard() {
  // Placeholder data - replace with actual data fetching logic
  const totalAVsCompleted = 100;
  const totalAVsToBatch = 150;
  const averageTimeToCloseWeek = '3 days';
  const averageTimeToCloseMonth = '5 days';
  const averageTimeToCloseYear = '7 days';

  const pipelineAVStats: StatisticsData = {
    'Total Open': 50,
    'In Annual Verification': 20,
    'In Pending Verification': 10,
    'In Employment in Progress': 5,
    'In WHOIS Update in Progress': 5,
    'In Verification Complete': 10,
  };

  const pendingQCAVStats: StatisticsData = {
    'In Verification Completed': 15,
  };

  const pipelineNewOrderStats: StatisticsData = {
    'Total Open': 30,
    'Pending Verification': 10,
    'Organization in Progress': 5,
    'Name Selection in Progress': 5,
    'Employment in Progress': 3,
    'Verification Complete': 5,
    'Pending Completion': 2,
  };

  const pendingQCNewOrderStats: StatisticsData = {
    'Pending QC/Registration': 7,
  };

  const tasks: Task[] = [
    {
      id: 'TASK-1001',
      title: 'Annual Verification for Bank of Example',
      status: 'In Progress',
      priority: 'High',
      dueDate: '2024-03-15',
    },
    {
      id: 'TASK-1002',
      title: 'New Domain Registration for InsureCo',
      status: 'Pending Verification',
      priority: 'Medium',
      dueDate: '2024-03-20',
    },
    {
      id: 'TASK-1003',
      title: 'WHOIS Update for GlobalBank',
      status: 'Completed',
      priority: 'Low',
      dueDate: '2024-03-10',
    },
    {
      id: 'TASK-1004',
      title: 'Security Audit for TrustFinance',
      status: 'Not Started',
      priority: 'High',
      dueDate: '2024-03-25',
    },
    {
      id: 'TASK-1005',
      title: 'Domain Renewal for SafeInsure',
      status: 'Pending Payment',
      priority: 'Medium',
      dueDate: '2024-03-18',
    },
  ];

  return (
    <Container className="mt-8 pb-12">
      <div className="mx-auto max-w-7xl">
        <h1 className="mb-6 text-3xl font-bold">Dashboard</h1>

        <div className="mb-6">
          <Input type="search" placeholder="Search tasks, domains, or contacts..." />
        </div>

        <Card className="mb-6">
          <CardHeader>
            <div className="flex items-center justify-between">
              <div>
                <CardTitle className="text-xl font-semibold">
                  Total AVs Completed / Total Number of AVs to Batch YTD
                </CardTitle>
                <p className="mt-2 text-3xl font-bold">
                  {totalAVsCompleted}
                  {' '}
                  /
                  {totalAVsToBatch}
                </p>
              </div>
              <Button>View Annual Verification Pipeline</Button>
            </div>
          </CardHeader>
        </Card>

        <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
          <ColumnStatistics
            title="Pipeline"
            statistics={pipelineAVStats}
          />
          <ColumnStatistics
            title="Pending QC"
            statistics={pendingQCAVStats}
          />
        </div>

        <h2 className="mb-4 text-xl font-semibold">Average Time from Open to Close</h2>
        <div className="mb-6 grid grid-cols-3 gap-4">
          <Card>
            <CardHeader>
              <CardTitle className="text-sm font-medium text-muted-foreground">
                This Week
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-bold">{averageTimeToCloseWeek}</p>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="text-sm font-medium text-muted-foreground">
                This Month
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-bold">{averageTimeToCloseMonth}</p>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="text-sm font-medium text-muted-foreground">
                This Year
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-2xl font-bold">{averageTimeToCloseYear}</p>
            </CardContent>
          </Card>
        </div>

        <Card className="mb-6">
          <CardHeader>
            <div className="flex items-center justify-between">
              <CardTitle className="text-xl font-semibold">New Orders</CardTitle>
              <Button>View New Order Pipeline</Button>
            </div>
          </CardHeader>
        </Card>

        <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
          <ColumnStatistics
            title="Pipeline"
            statistics={pipelineNewOrderStats}
          />
          <ColumnStatistics
            title="Pending QC/Registration"
            statistics={pendingQCNewOrderStats}
          />
        </div>

        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-semibold">Tasks</CardTitle>
          </CardHeader>
          <CardContent>
            <DataGrid data={tasks} />
          </CardContent>
        </Card>
      </div>
    </Container>
  );
}

export default Dashboard;
