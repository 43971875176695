import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import type { Grant, URLForAuthenticationConfig } from 'nylas';
import useAxios from './use-axios';

interface NylasGrantsResponse {
  grants: Grant[];
  requestId: string;
}

interface CreateGrantResponse {
  authUrl: string;
  message: string;
}

interface DeleteGrantResponse {
  success: boolean;
  requestId: string;
}

export const NYLAS_QUERY_KEYS = {
  grants: {
    all: ['nylasGrants'] as const,
    list: () => [...NYLAS_QUERY_KEYS.grants.all, 'list'] as const,
  },
} as const;

export function useNylasGrants() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: NYLAS_QUERY_KEYS.grants.list(),
    queryFn: async () => {
      const { data } = await axios.get<NylasGrantsResponse>('/nylas/grants');
      return data.grants;
    },
  });

  const createGrantMutation = useMutation({
    mutationFn: async (config: Partial<URLForAuthenticationConfig>) => {
      const { data } = await axios.post<CreateGrantResponse>('/nylas/grants', config);
      return data;
    },
    onSuccess: (data) => {
      // Redirect user to Nylas auth URL to complete OAuth flow
      console.log('Redirecting to:', data.authUrl);
      globalThis.location.href = data.authUrl;
    },
  });

  const deleteGrantMutation = useMutation({
    mutationFn: async (grantId: string) => {
      const { data } = await axios.delete<DeleteGrantResponse>(`/nylas/grants/${grantId}`);
      return data;
    },
    onSuccess: async () => {
      // Invalidate and refetch the grants list
      await queryClient.invalidateQueries({ queryKey: NYLAS_QUERY_KEYS.grants.list() });
    },
  });

  return {
    ...query,
    createGrant: createGrantMutation.mutate,
    isCreatingGrant: createGrantMutation.isPending,
    createGrantError: createGrantMutation.error,
    deleteGrant: deleteGrantMutation.mutate,
    isDeletingGrant: deleteGrantMutation.isPending,
    deleteGrantError: deleteGrantMutation.error,
  };
}
