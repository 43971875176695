import { useRef, useState } from 'react';
import type { GridApi } from '@ag-grid-community/core';
import { RotateCcw } from 'lucide-react';
import { toast } from 'sonner';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useGrid } from '@/contexts/grid-context';
import {
  useEmailTemplates,
  useCreateEmailTemplate,
  useUpdateEmailTemplate,
  useDeleteEmailTemplates,
  type EmailTemplate,
} from '@/hooks/use-email-templates';
import { templateColumnDefs } from './components/template-grid-columns';
import TemplateSheet from './components/template-sheet';

function TemplatesPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState<EmailTemplate[]>([]);
  const [editingTemplate, setEditingTemplate] = useState<EmailTemplate | null>(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const gridApiRef = useRef<GridApi<EmailTemplate> | null>(null);
  const { registerGridApi } = useGrid();

  const { data: emailTemplates, isLoading } = useEmailTemplates();
  const createMutation = useCreateEmailTemplate();
  const updateMutation = useUpdateEmailTemplate();
  const deleteMutation = useDeleteEmailTemplates();

  const handleCreateSubmit = async (data: {
    name: string;
    short_description: string;
    long_description: string;
    tld: string;
    template: { body: string };
    subject: string;
  }) => {
    try {
      await createMutation.mutateAsync(data);
      toast.success('Template created successfully');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
      throw error;
    }
  };

  const handleUpdateSubmit = async (data: {
    name: string;
    short_description: string;
    long_description: string;
    tld: string;
    template: { body: string };
    subject: string;
  }) => {
    if (!editingTemplate)
      return;

    try {
      // Keep the original name for the API path, but allow updating other fields
      const { name, ...updateData } = data;
      await updateMutation.mutateAsync({
        name: editingTemplate.name,
        ...updateData,
      });
      toast.success('Template updated successfully');
      setEditingTemplate(null);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
      throw error;
    }
  };

  const handleDelete = async () => {
    if (selectedRows.length === 0)
      return;

    try {
      await deleteMutation.mutateAsync(selectedRows.map((row) => row.name));
      toast.success('Selected templates deleted successfully');
      setSelectedRows([]);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const handleRowDoubleClick = (event: { data: EmailTemplate | undefined }) => {
    if (!event.data)
      return;
    setEditingTemplate(event.data);
    setIsSheetOpen(true);
  };

  const handleSelectionChanged = (event: { api: { getSelectedRows: () => EmailTemplate[] } }) => {
    setSelectedRows(event.api.getSelectedRows());
  };

  const handleSheetOpenChange = (open: boolean) => {
    setIsSheetOpen(open);
    if (!open) {
      setEditingTemplate(null);
      // Deselect rows when closing the sheet
      if (gridApiRef.current) {
        gridApiRef.current.deselectAll();
      }
    }
  };

  const handleGridReady = (params: { api: GridApi<EmailTemplate> }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  const clearFilters = () => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      setSearchQuery('');
    }
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDeleteDialogOpen(true);
  };

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-3 flex justify-between">
        <div>
          <h1 className="mt-2 text-xl font-bold">Email Templates</h1>
          <p className="text-sm text-muted-foreground">
            Create and manage email templates. Use
            {' '}
            {'{{'}
            {' '}
            and
            {' '}
            {'}}'}
            {' '}
            syntax for template variables (e.g.
            {' '}
            {'{{name}}'}
            ).
          </p>
        </div>
        <div className="flex items-center gap-4">
          {selectedRows.length > 0 && (
            <Button
              variant="destructive"
              onClick={handleDeleteClick}
              disabled={deleteMutation.isPending}
            >
              Delete Selected
            </Button>
          )}
          <Button onClick={() => setIsSheetOpen(true)}>
            Create Template
          </Button>
        </div>
      </div>
      <div className="mb-6 flex gap-4">
        <div className="flex-1">
          <Input
            type="search"
            placeholder="Search templates..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button
          variant="outline"
          onClick={clearFilters}
          className="gap-2"
        >
          <RotateCcw className="size-4" />
          Clear Filters
        </Button>
      </div>
      <div className={isLoading ? 'opacity-50' : ''}>
        <AGDataGrid
          columnDefs={templateColumnDefs}
          rowData={emailTemplates || []}
          quickFilterText={searchQuery}
          rowSelection="multiple"
          onRowDoubleClicked={handleRowDoubleClick}
          onSelectionChanged={handleSelectionChanged}
          onGridReady={handleGridReady}
          overlayLoadingTemplate={
            isLoading ? '<span class="ag-overlay-loading-center">Loading...</span>' : undefined
          }
          overlayNoRowsTemplate={
            isLoading ? '' : '<span class="ag-overlay-no-rows-center">No templates found</span>'
          }
        />
      </div>

      <TemplateSheet
        open={isSheetOpen}
        onOpenChange={handleSheetOpenChange}
        initialData={editingTemplate || undefined}
        onSubmit={editingTemplate ? handleUpdateSubmit : handleCreateSubmit}
      />

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete
              {' '}
              {selectedRows.length}
              {' '}
              selected
              {' '}
              {selectedRows.length === 1 ? 'template' : 'templates'}
              .
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Container>
  );
}

export default TemplatesPage;
