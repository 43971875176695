import type { FileSearchParams } from '@/types/drive';

export const {
  VITE_BASE_URL: BASE_URL,
  VITE_CLERK_PUBLISHABLE_KEY: CLERK_PUBLISHABLE_KEY,
  VITE_API_BASE_URL: API_BASE_URL,
  VITE_MONGO_URL: MONGO_URL,
  VITE_AG_GRID_LICENSE_KEY: AG_GRID_LICENSE_KEY,
} = import.meta.env;

// header defaults
export const DEFAULT_TITLE = 'fTLD Ticketing';
export const DEFAULT_DESCRIPTION = 'fTLD Ticketing';
export const DEFAULT_URL = 'https://ftld-dev.do.infonetworks.dev';
export const DEFAULT_IMAGE = '/assets/ftld-logo.svg';

// React Query keys
export const DRIVE_QUERY_KEYS = {
  files: {
    all: ['driveFiles'] as const,
    list: (params: FileSearchParams) => [...DRIVE_QUERY_KEYS.files.all, 'list', params] as const,
    detail: (fileId: string) => [...DRIVE_QUERY_KEYS.files.all, 'detail', fileId] as const,
  },
} as const;
