import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import { CircularProgress } from '@mui/material';

interface ProtectedRouteProps {
  component: React.ComponentType;
  fallbackPath?: string;
}

function ProtectedRoute({ component: Component, fallbackPath = '/login' }: ProtectedRouteProps) {
  const { isLoaded, isSignedIn } = useAuth();

  if (!isLoaded) {
    return (
      <div className="flex h-svh flex-col items-center justify-center space-y-4">
        <h2 className="text-xl font-medium">Loading...</h2>
        <CircularProgress />
      </div>
    );
  }

  if (!isSignedIn) {
    return <Navigate to={fallbackPath} replace />;
  }

  return <Component />;
}

export default ProtectedRoute;
