import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type { BreadcrumbContextValue, BreadcrumbSegment } from './types';

/**
 * Context for managing dynamic breadcrumb labels throughout the application.
 * This allows pages to customize their breadcrumb representation beyond the URL path.
 *
 * @example
 * // In a detail page:
 * const { setLabel } = useBreadcrumb();
 * setLabel('/app/contacts/123', { name: 'John Smith', path: '/app/contacts/123' });
 */
const BreadcrumbLabelContext = createContext<BreadcrumbContextValue | undefined>(undefined);

type BreadcrumbProviderProps = {
  /** The child components that will have access to the breadcrumb context */
  children: React.ReactNode;
};

/**
 * Provider component that manages the state of custom breadcrumb labels.
 * Wrap this around components that need to customize their breadcrumb representation.
 */
export function BreadcrumbProvider({ children }: BreadcrumbProviderProps) {
  const [labels, setLabels] = useState<Record<string, BreadcrumbSegment>>({});

  const setLabel = useCallback((path: string, segment: BreadcrumbSegment) => {
    setLabels((prev) => ({
      ...prev,
      [path]: segment,
    }));
  }, []);

  const removeLabel = useCallback((path: string) => {
    setLabels((prev) => {
      const next = { ...prev };
      delete next[path];
      return next;
    });
  }, []);

  const value = useMemo(() => ({
    labels,
    setLabel,
    removeLabel,
  }), [labels, setLabel, removeLabel]);

  return (
    <BreadcrumbLabelContext.Provider value={value}>
      {children}
    </BreadcrumbLabelContext.Provider>
  );
}

/**
 * Hook to access and modify breadcrumb labels.
 * Must be used within a BreadcrumbProvider component.
 *
 * @returns Methods to get, set, and remove custom breadcrumb labels
 * @throws Error if used outside of a BreadcrumbProvider
 */
export function useBreadcrumb(): BreadcrumbContextValue {
  const context = useContext(BreadcrumbLabelContext);
  if (context === undefined) {
    throw new Error('useBreadcrumb must be used within a BreadcrumbProvider');
  }
  return context;
}
