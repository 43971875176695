// src/pages/app/deals/components/message-input.tsx
import { useCallback, useState } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Placeholder from '@tiptap/extension-placeholder';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import {
  Bold,
  Italic,
  Strikethrough,
  Code,
  Paperclip,
  Smile,
  AtSign,
  Hash,
  Send,
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

interface MessageInputProps {
  onSend: (content: string) => void;
  onUploadFiles?: (files: File[]) => void;
}

export function MessageInput({ onSend, onUploadFiles }: MessageInputProps) {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        // Disable features we don't want in chat messages
        heading: false,
        codeBlock: false, // This was causing the error
        horizontalRule: false,
        table: false,
        // Configure the features we want
        bold: true,
        italic: true,
        strike: true,
        code: true,
        bulletList: true,
        orderedList: true,
      }),
      Placeholder.configure({
        placeholder: 'Type a message...',
      }),
    ],
    editorProps: {
      attributes: {
        class: 'prose prose-sm max-w-none focus:outline-none min-h-[40px] max-h-[300px] overflow-y-auto px-3 py-2',
      },
    },
  });

  const handleSend = useCallback(() => {
    if (!editor?.getText().trim())
      return;

    onSend(editor.getHTML());
    editor.commands.clearContent();
  }, [editor, onSend]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  }, [handleSend]);

  const handleFileUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const files = [...e.target.files || []];
    if (files.length > 0 && onUploadFiles) {
      onUploadFiles(files);
      e.target.value = ''; // Reset input
    }
  }, [onUploadFiles]);

  const insertEmoji = useCallback((emoji: any) => {
    editor?.commands.insertContent(emoji.native);
    setShowEmojiPicker(false);
  }, [editor]);

  return (
    <div className="border-t bg-background p-4">
      <div className="rounded-md border bg-background">
        <EditorContent
          editor={editor}
          onKeyDown={handleKeyDown}
        />
        <div className="flex items-center justify-between border-t bg-muted/40 px-2 py-1">
          <div className="flex items-center gap-0.5">
            <Button
              variant="ghost"
              size="sm"
              className="size-8 p-0"
              onClick={() => editor?.chain().focus().toggleBold().run()}
              data-active={editor?.isActive('bold')}
            >
              <Bold className="size-4" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              className="size-8 p-0"
              onClick={() => editor?.chain().focus().toggleItalic().run()}
              data-active={editor?.isActive('italic')}
            >
              <Italic className="size-4" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              className="size-8 p-0"
              onClick={() => editor?.chain().focus().toggleStrike().run()}
              data-active={editor?.isActive('strike')}
            >
              <Strikethrough className="size-4" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              className="size-8 p-0"
              onClick={() => editor?.chain().focus().toggleCode().run()}
              data-active={editor?.isActive('code')}
            >
              <Code className="size-4" />
            </Button>

            <div className="mx-1 h-4 w-px bg-border" />

            <Popover open={showEmojiPicker} onOpenChange={setShowEmojiPicker}>
              <PopoverTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  className="size-8 p-0"
                >
                  <Smile className="size-4" />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                className="w-auto border-none p-0"
                sideOffset={5}
                align="start"
              >
                <Picker
                  data={data}
                  onEmojiSelect={insertEmoji}
                  theme="light"
                  previewPosition="none"
                />
              </PopoverContent>
            </Popover>

            <Button
              variant="ghost"
              size="sm"
              className="size-8 p-0"
              onClick={() => editor?.commands.insertContent('@')}
            >
              <AtSign className="size-4" />
            </Button>

            <Button
              variant="ghost"
              size="sm"
              className="size-8 p-0"
              onClick={() => editor?.commands.insertContent('#')}
            >
              <Hash className="size-4" />
            </Button>

            <div className="mx-1 h-4 w-px bg-border" />

            <div className="relative">
              <Button
                variant="ghost"
                size="sm"
                className="size-8 p-0"
                onClick={() => document.querySelector('#file-upload')?.click()}
              >
                <Paperclip className="size-4" />
              </Button>
              <input
                id="file-upload"
                type="file"
                multiple
                className="hidden"
                onChange={handleFileUpload}
              />
            </div>
          </div>

          <Button
            size="sm"
            onClick={handleSend}
            disabled={!editor?.getText().trim()}
          >
            <Send className="mr-2 size-4" />
            Send
          </Button>
        </div>
      </div>
      <p className="mt-2 text-xs text-muted-foreground">
        Press Enter to send, Shift + Enter for new line
      </p>
    </div>
  );
}
