import * as React from 'react'
import './styles/index.css'

import type { Content, Editor } from '@tiptap/react'
import type { UseTiptapEditorProps } from './hooks/use-tiptap'
import { EditorContent } from '@tiptap/react'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'
import { SectionOne } from './components/section/one'
import { SectionTwo } from './components/section/two'
import { SectionThree } from './components/section/three'
import { SectionFour } from './components/section/four'
import { SectionFive } from './components/section/five'
import { SectionTasks } from './components/section/tasks'
import { CommandPaletteSection } from './components/section/command-palette'
import { MessageActions } from './components/section/message-actions'
import { LinkBubbleMenu } from './components/bubble-menu/link-bubble-menu'
import { useTiptapEditor } from './hooks/use-tiptap'
import { MeasuredContainer } from './components/measured-container'

export interface TiptapProps extends Omit<UseTiptapEditorProps, 'onUpdate'> {
  value?: Content
  onChange?: (value: Content) => void
  className?: string
  editorContentClassName?: string
  onOpenCommandPalette?: () => void
  hideSections?: string[]
  isEmailMode?: boolean
  onEmailModeChange?: (isEmail: boolean) => void
  onTemplateClick?: () => void
}

const Toolbar = ({
  editor,
  onOpenCommandPalette,
  hideSections = [],
  isEmailMode,
  onEmailModeChange,
  onTemplateClick,
}: {
  editor: Editor
  onOpenCommandPalette?: () => void
  hideSections?: string[]
  isEmailMode?: boolean
  onEmailModeChange?: (isEmail: boolean) => void
  onTemplateClick?: () => void
}) => (
  <div className="shrink-0 overflow-x-auto border-b border-border p-2">
    <div className="flex w-full items-center gap-px">
      <div className="flex items-center gap-px">
        {!hideSections.includes('one') && (
          <>
            <SectionOne editor={editor} activeLevels={[1, 2, 3, 4, 5, 6]} />
            <Separator orientation="vertical" className="mx-2 h-7" />
          </>
        )}

        {!hideSections.includes('two') && (
          <>
            <SectionTwo
              editor={editor}
              activeActions={['bold', 'italic', 'underline', 'strikethrough', 'code', 'clearFormatting']}
              mainActionCount={3}
            />
            <Separator orientation="vertical" className="mx-2 h-7" />
          </>
        )}

        {!hideSections.includes('three') && (
          <>
            <SectionThree editor={editor} />
            <Separator orientation="vertical" className="mx-2 h-7" />
          </>
        )}

        {!hideSections.includes('tasks') && (
          <>
            <SectionTasks editor={editor} activeActions={['taskList']} mainActionCount={1} />
            <Separator orientation="vertical" className="mx-2 h-7" />
          </>
        )}

        {!hideSections.includes('four') && (
          <>
            <SectionFour editor={editor} activeActions={['orderedList', 'bulletList']} mainActionCount={0} />
            <Separator orientation="vertical" className="mx-2 h-7" />
          </>
        )}

        {!hideSections.includes('five') && (
          <>
            <SectionFive editor={editor} activeActions={['codeBlock', 'blockquote', 'horizontalRule']} mainActionCount={0} />
          </>
        )}

        {onOpenCommandPalette && !hideSections.includes('command-palette') && (
          <>
            <Separator orientation="vertical" className="mx-2 h-7" />
            <CommandPaletteSection editor={editor} onOpen={onOpenCommandPalette} />
          </>
        )}
      </div>

      {!hideSections.includes('message-actions') && (onEmailModeChange || onTemplateClick) && (
        <div className="ml-auto flex items-center">
          <Separator orientation="vertical" className="mx-2 h-7" />
          <MessageActions
            editor={editor}
            isEmailMode={isEmailMode}
            onEmailModeChange={onEmailModeChange}
            onTemplateClick={onTemplateClick}
          />
        </div>
      )}
    </div>
  </div>
)

export const TiptapEditor = React.forwardRef<HTMLDivElement, TiptapProps>(
  ({
    value,
    onChange,
    className,
    editorContentClassName,
    onOpenCommandPalette,
    hideSections,
    isEmailMode,
    onEmailModeChange,
    onTemplateClick,
    ...props
  }, ref) => {
    const editor = useTiptapEditor({
      value,
      onUpdate: onChange,
      ...props
    })

    if (!editor) {
      return null
    }

    return (
      <MeasuredContainer
        as="div"
        name="editor"
        ref={ref}
        className={cn(
          'flex h-auto min-h-72 w-full flex-col rounded-md border border-input shadow-sm focus-within:border-primary',
          className
        )}
      >
        <Toolbar
          editor={editor}
          onOpenCommandPalette={onOpenCommandPalette}
          hideSections={hideSections}
          isEmailMode={isEmailMode}
          onEmailModeChange={onEmailModeChange}
          onTemplateClick={onTemplateClick}
        />
        <EditorContent editor={editor} className={cn('minimal-tiptap-editor', editorContentClassName)} />
        <LinkBubbleMenu editor={editor} />
      </MeasuredContainer>
    )
  }
)

TiptapEditor.displayName = 'TiptapEditor'

export default TiptapEditor
