// src/pages/app/deals/components/deal-grid-columns.tsx
import { Link } from 'react-router-dom';
import type { ICellRendererParams, ColDef } from '@ag-grid-community/core';
import { formatDateEDT } from '@/lib/date';
import type { Deal } from '@/types/deal';

function DealLinkCell({ value, data }: ICellRendererParams<Deal, string>) {
  if (!data?.id || !value)
    return null;
  return (
    <Link to={`/app/deals/${data.id}`} className="text-primary hover:underline">
      {value}
    </Link>
  );
}

function PriorityCell({ value }: ICellRendererParams<Deal, Deal['priority']>) {
  const styles = {
    low: 'bg-green-100 text-green-800',
    medium: 'bg-yellow-100 text-yellow-800',
    high: 'bg-red-100 text-red-800',
  };

  return (
    <span className={`rounded-full px-2 py-1 text-xs font-medium ${styles[value]}`}>
      {value.charAt(0).toUpperCase() + value.slice(1)}
    </span>
  );
}

function StatusCell({ value }: ICellRendererParams<Deal, Deal['status']>) {
  const styles = {
    active: 'bg-green-100 text-green-800',
    inactive: 'bg-gray-100 text-gray-800',
    completed: 'bg-blue-100 text-blue-800',
  };

  return (
    <span className={`rounded-full px-2 py-1 text-xs font-medium ${styles[value]}`}>
      {value.charAt(0).toUpperCase() + value.slice(1)}
    </span>
  );
}

function DateCell({ value }: ICellRendererParams<Deal, string>) {
  return <div className="text-muted-foreground">{formatDateEDT(value)}</div>;
}

export const dealColumnDefs: ColDef<Deal>[] = [
  {
    field: 'title',
    headerName: 'Deal',
    width: 250,
    cellRenderer: DealLinkCell,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 200,
  },
  {
    field: 'stage',
    headerName: 'Stage',
    width: 200,
  },
  {
    field: 'value',
    headerName: 'Value',
    width: 150,
    valueFormatter: (params) =>
      new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
        .format(params.value),
  },
  {
    field: 'priority',
    headerName: 'Priority',
    width: 120,
    cellRenderer: PriorityCell,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    cellRenderer: StatusCell,
  },
  {
    field: 'assignedTo',
    headerName: 'Assigned To',
    width: 150,
  },
  {
    field: 'updatedAt',
    headerName: 'Last Updated',
    width: 150,
    cellRenderer: DateCell,
  },
];

export const sampleDealData: Deal[] = [
  {
    id: '1',
    title: 'Annual Verification - Bank of Example',
    company: 'Bank of Example',
    stage: 'Annual Verification',
    value: 5000,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    assignedTo: 'John Doe',
    priority: 'high',
    status: 'active',
  },
  // Add more sample data...
];
