import { useState } from 'react';
import { toast } from 'sonner';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from '@/components/ui/alert-dialog';
import { Input } from '@/components/ui/input';
import { useShareFile } from '@/hooks/use-drive';
import type { SharePermission } from '@/types/drive';

type ShareDialogProps = {
  fileId: string;
  fileName: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

function ShareDialog({ fileId, fileName, open, onOpenChange }: ShareDialogProps) {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<SharePermission['role']>('reader');
  const shareMutation = useShareFile();

  const handleShare = async () => {
    if (!email) {
      toast.error('Please enter an email address');
      return;
    }

    try {
      const response = await shareMutation.mutateAsync({
        fileId,
        permission: {
          type: 'user',
          role,
          emailAddress: email,
        },
      });

      if (response.success) {
        toast.success('File shared successfully');
        onOpenChange(false);
        setEmail('');
      } else {
        toast.error(response.error || 'Failed to share file');
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Failed to share file');
    }
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Share File</AlertDialogTitle>
          <AlertDialogDescription className="space-y-2">
            <p>
              Share "
              {fileName}
              " with another user.
            </p>
            <p className="text-xs text-muted-foreground">
              The file will appear in their "Shared with me" section in Google Drive.
            </p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid gap-2">
            <Input
              type="email"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="grid gap-2">
            <select
              className="flex h-9 w-full rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
              value={role}
              onChange={(e) => setRole(e.target.value as SharePermission['role'])}
            >
              <option value="reader">Reader (read-only access)</option>
              <option value="writer">Writer (read and write access)</option>
            </select>
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleShare} disabled={shareMutation.isPending}>
            {shareMutation.isPending ? 'Sharing...' : 'Share'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default ShareDialog;
