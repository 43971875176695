// src/pages/app/deals/components/deal-columns.tsx
import { Link } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import type { Deal, DealStage } from '@/types/deal';

const STAGES: DealStage[] = [
  'Annual Verification',
  'Pending Verification',
  'Organization Verification in Progress',
  'Name Selection in Progress',
  'Employment Verification in Progress',
  'WHOIS Update in Progress',
  'Failed Verification',
  'Verification Completed',
  'Verified Data Sent',
];

function DealCard({ deal }: { deal: Deal }) {
  return (
    <Card>
      <CardContent className="p-4">
        <Link
          to={`/app/deals/${deal.id}`}
          className="block text-base font-medium hover:text-primary hover:underline"
        >
          {deal.title}
        </Link>
        <div className="mt-2 space-y-1 text-sm text-muted-foreground">
          <p>{deal.company}</p>
          <p>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(deal.value)}
          </p>
          <div className="flex items-center justify-between">
            <span>{deal.assignedTo}</span>
            <span className={`rounded-full px-2 py-0.5 text-xs font-medium
              ${deal.priority === 'high'
      ? 'bg-red-100 text-red-800'
      : (deal.priority === 'medium'
          ? 'bg-yellow-100 text-yellow-800'
          : 'bg-green-100 text-green-800')}`}
            >
              {deal.priority}
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export function DealColumns({ deals }: { deals: Deal[] }) {
  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {STAGES.map((stage) => {
        const stageDeals = deals.filter((deal) => deal.stage === stage);
        const count = stageDeals.length;

        return (
          <Card key={stage}>
            <CardHeader>
              <CardTitle className="flex items-center justify-between text-sm font-medium">
                <span>{stage}</span>
                <span className="text-muted-foreground">
                  (
                  {count}
                  )
                </span>
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {count > 0
                ? (
                    stageDeals.map((deal) => (
                      <DealCard key={deal.id} deal={deal} />
                    ))
                  )
                : (
                    <p className="text-center text-sm text-muted-foreground">
                      No deals in this stage
                    </p>
                  )}
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
}
