import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBreadcrumb } from '@/components/breadcrumb-provider';
import Container from '@/components/container';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import type { Company } from '@/types/company';

type TimelineEvent = {
  id: string;
  type: 'update' | 'create';
  message: string;
  timestamp: Date;
  user: string;
};

function CompanyAvatar({ name }: { name: string }) {
  return (
    <Avatar className="size-12">
      <AvatarFallback className="text-lg font-medium">
        {name.charAt(0).toUpperCase()}
      </AvatarFallback>
    </Avatar>
  );
}

function InfoField({ label, value }: { label: string; value: string | undefined }) {
  if (!value)
    return null;
  return (
    <div className="space-y-1">
      <p className="text-sm text-muted-foreground">{label}</p>
      <p className="text-sm font-medium">{value}</p>
    </div>
  );
}

function Timeline({ events }: { events: TimelineEvent[] }) {
  return (
    <div className="space-y-4">
      {events.map((event) => (
        <div key={event.id} className="flex gap-4">
          <div className="relative mt-1 flex size-2">
            <div className="absolute inline-flex size-2 rounded-full bg-primary" />
          </div>
          <div className="flex-1 space-y-1">
            <p className="text-sm text-foreground">{event.message}</p>
            <div className="flex items-center gap-2 text-xs text-muted-foreground">
              <span>{event.user}</span>
              <span>•</span>
              <span>{event.timestamp.toLocaleString()}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function ContactDetailsPage() {
  const { id } = useParams();
  const { setLabel, removeLabel } = useBreadcrumb();
  const [company, setCompany] = useState<Company>({
    legalName: 'Apple',
    dbaNames: [],
    primaryAddress: {
      streetAddress: '123 Apple St.',
      postalCode: '12345',
      city: 'Cupertino',
      state: 'CA',
      country: 'US',
    },
    incorporationCountry: 'US',
    entityType: 'corporation',
    regulatoryAuthority: {
      name: 'SEC',
      jurisdiction: 'US',
    },
    licenses: [],
  });

  const [events] = useState<TimelineEvent[]>([
    {
      id: '1',
      type: 'create',
      message: 'Apple was created by You',
      timestamp: new Date(),
      user: 'You',
    },
    {
      id: '2',
      type: 'update',
      message: 'You updated a related task Test',
      timestamp: new Date(),
      user: 'You',
    },
  ]);

  useEffect(() => {
    const path = `/app/contacts/${id}`;
    setLabel(path, {
      name: company.legalName,
      path,
    });
    return () => removeLabel(path);
  }, [id, company.legalName, setLabel, removeLabel]);

  return (
    <Container className="mt-8 pb-12">
      <div className="grid grid-cols-12 gap-6">
        {/* Left Sidebar */}
        <div className="col-span-3">
          <Card>
            <CardContent className="p-6">
              <div className="flex flex-col items-center gap-2">
                <CompanyAvatar name={company.legalName} />
                <div className="w-full space-y-6">
                  <div className="text-center">
                    <h2 className="text-xl font-semibold">{company.legalName}</h2>
                    <p className="text-sm text-muted-foreground">Added about 4 hours ago</p>
                  </div>
                  <Separator />
                  <InfoField label="Domains" value="apple.com, apple.dev" />
                  <InfoField
                    label="Address"
                    value={`${company.primaryAddress.streetAddress}, ${company.primaryAddress.city}, ${company.primaryAddress.state} ${company.primaryAddress.postalCode}`}
                  />
                  <InfoField label="Links" value="Empty" />
                  <InfoField label="Last update" value="about 4 hours ago" />
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="mt-6">
            <CardHeader>
              <CardTitle>Primary Contact</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-muted-foreground">No primary contact set</p>
            </CardContent>
          </Card>

          <Card className="mt-6">
            <CardHeader>
              <CardTitle>Licenses</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-muted-foreground">No licenses added</p>
            </CardContent>
          </Card>
        </div>

        {/* Main Content */}
        <div className="col-span-9">
          <Card className="overflow-hidden">
            <Tabs defaultValue="timeline" className="w-full">
              <div className="border-b">
                <TabsList className="h-auto w-full justify-start rounded-none bg-transparent p-0">
                  <TabsTrigger
                    value="timeline"
                    className="relative rounded-none border-b-2 border-transparent px-4 py-3 data-[state=active]:border-primary data-[state=active]:bg-transparent"
                  >
                    Timeline
                  </TabsTrigger>
                  <TabsTrigger
                    value="notes"
                    className="relative rounded-none border-b-2 border-transparent px-4 py-3 data-[state=active]:border-primary data-[state=active]:bg-transparent"
                  >
                    Notes
                  </TabsTrigger>
                  <TabsTrigger
                    value="tasks"
                    className="relative rounded-none border-b-2 border-transparent px-4 py-3 data-[state=active]:border-primary data-[state=active]:bg-transparent"
                  >
                    Tasks
                  </TabsTrigger>
                  <TabsTrigger
                    value="files"
                    className="relative rounded-none border-b-2 border-transparent px-4 py-3 data-[state=active]:border-primary data-[state=active]:bg-transparent"
                  >
                    Files
                  </TabsTrigger>
                  <TabsTrigger
                    value="emails"
                    className="relative rounded-none border-b-2 border-transparent px-4 py-3 data-[state=active]:border-primary data-[state=active]:bg-transparent"
                  >
                    Emails
                  </TabsTrigger>
                </TabsList>
              </div>
              <ScrollArea className="h-[calc(100vh-12rem)]">
                <TabsContent value="timeline" className="m-0 p-6 focus-visible:outline-none focus-visible:ring-0">
                  <div className="space-y-2">
                    <h3 className="font-semibold">November 2024</h3>
                    <Timeline events={events} />
                  </div>
                </TabsContent>
                <TabsContent value="tasks" className="m-0 p-6 focus-visible:outline-none focus-visible:ring-0">
                  <p className="text-sm text-muted-foreground">Tasks content coming soon</p>
                </TabsContent>
                <TabsContent value="notes" className="m-0 p-6 focus-visible:outline-none focus-visible:ring-0">
                  <p className="text-sm text-muted-foreground">Notes content coming soon</p>
                </TabsContent>
                <TabsContent value="files" className="m-0 p-6 focus-visible:outline-none focus-visible:ring-0">
                  <p className="text-sm text-muted-foreground">Files content coming soon</p>
                </TabsContent>
                <TabsContent value="emails" className="m-0 p-6 focus-visible:outline-none focus-visible:ring-0">
                  <p className="text-sm text-muted-foreground">Emails content coming soon</p>
                </TabsContent>
              </ScrollArea>
            </Tabs>
          </Card>
        </div>
      </div>
    </Container>
  );
}

export default ContactDetailsPage;
