import { parseISO, isValid, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export function formatDateEDT(date: string | Date): string {
  const dateObj = date instanceof Date ? date : new Date(date);

  return formatInTimeZone(
    dateObj,
    'America/New_York',
    'MMM dd, yyyy h:mm a \'EDT\'',
  );
}

/**
 * Formats a date string to a given format string.
 */
export function formatDate(dateString: string, formatStr: string = 'PP p'): string {
  const date = parseISO(dateString);
  if (!isValid(date))
    return '';

  // If no format string is given, fallback to the EDT format.
  if (!formatStr) {
    return formatDateEDT(date);
  }

  return format(date, formatStr);
}
