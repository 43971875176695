import * as React from 'react'
import type { Editor } from '@tiptap/react'
import type { FormatAction } from '../../types'
import type { toggleVariants } from '@/components/ui/toggle'
import type { VariantProps } from 'class-variance-authority'
import { ListTodo, /* IndentDecrease, IndentIncrease */ } from 'lucide-react';
import { ToolbarSection } from '../toolbar-section';

type TaskListAction = 'taskList' | 'indentTask' | 'outdentTask'

interface TaskAction extends FormatAction {
  value: TaskListAction
}

const taskListActions: TaskAction[] = [
  {
    value: 'taskList',
    label: 'Task List',
    icon: <ListTodo className="size-5" />,
    action: editor => editor.chain().focus().toggleTaskList().run(),
    isActive: editor => editor.isActive('taskList'),
    canExecute: editor => editor.can().toggleTaskList() && !editor.isActive('codeBlock'),
    shortcuts: []
  },
  // {
  //   value: 'indentTask',
  //   label: 'Indent Task',
  //   icon: <IndentIncrease className="size-5" />,
  //   action: editor => editor.chain().focus().sinkListItem('taskItem').run(),
  //   isActive: editor => editor.can().sinkListItem('taskItem'),
  //   canExecute: editor => editor.can().sinkListItem('taskItem') && !editor.isActive('codeBlock'),
  //   shortcuts: []
  // },
  // {
  //   value: 'outdentTask',
  //   label: 'Outdent Task',
  //   icon: <IndentDecrease className="size-5" />,
  //   action: editor => editor.chain().focus().liftListItem('taskItem').run(),
  //   isActive: editor => editor.can().liftListItem('taskItem'),
  //   canExecute: editor => editor.can().liftListItem('taskItem') && !editor.isActive('codeBlock'),
  //   shortcuts: []
  // },
]

interface SectionTasksProps extends VariantProps<typeof toggleVariants> {
  editor: Editor
  activeActions?: TaskListAction[]
  mainActionCount?: number
}

export const SectionTasks: React.FC<SectionTasksProps> = ({
  editor,
  activeActions = taskListActions.map(action => action.value),
  mainActionCount = 1,
  size,
  variant
}) => {
  return (
    <ToolbarSection
      editor={editor}
      actions={taskListActions}
      activeActions={activeActions}
      mainActionCount={mainActionCount}
      size={size}
      variant={variant}
    />
  )
}

SectionTasks.displayName = 'SectionTasks'

export default SectionTasks
