import { useAuth } from '@clerk/clerk-react';
import axios from 'axios';
import { API_BASE_URL } from '@/lib/globals';

// Create a custom Axios instance
export function useAxios() {
  const { getToken } = useAuth();

  // Instantiate a customized Axios instance
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Use Axios request interceptors to attach the token to every request
  axiosInstance.interceptors.request.use(
    async (config) => {
      try {
        const token = await getToken();
        config.headers.Authorization = `Bearer ${token}`;
      } catch (error) {
        console.error('Error getting the access token', error);
      }
      return config;
    },
    async (error) => {
      throw error;
    },
  );

  return axiosInstance;
}

export default useAxios;
