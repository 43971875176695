import type { ReactNode, HTMLAttributes } from 'react';
import React from 'react';

type MaxWidthOption =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | '7xl'
  | false;

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  maxWidth?: MaxWidthOption;
  disableGutters?: boolean;
  fixed?: boolean;
  children: ReactNode;
}

function getMaxWidthClass(width: MaxWidthOption): string {
  switch (width) {
    case 'xs': {
      return 'max-w-xs w-full';
    }
    case 'sm': {
      return 'max-w-sm w-full';
    }
    case 'md': {
      return 'max-w-md w-full';
    }
    case 'lg': {
      return 'max-w-lg w-full';
    }
    case 'xl': {
      return 'max-w-xl w-full';
    }
    case '2xl': {
      return 'max-w-2xl w-full';
    }
    case '3xl': {
      return 'max-w-3xl w-full';
    }
    case '4xl': {
      return 'max-w-4xl w-full';
    }
    case '5xl': {
      return 'max-w-5xl w-full';
    }
    case '6xl': {
      return 'max-w-6xl w-full';
    }
    case '7xl': {
      return 'max-w-7xl w-full';
    }
    case false: {
      return 'w-full';
    }
    default: {
      return 'w-full';
    }
  }
}

const Container: React.FC<ContainerProps> = ({
  maxWidth = '7xl',
  disableGutters = false,
  fixed = false,
  children,
  className,
  ...rest
}) => {
  const classes = [
    'flex-1 self-center mx-auto min-h-0',
    getMaxWidthClass(maxWidth),
    !disableGutters && 'px-4 sm:px-6 md:px-8',
    fixed && 'container',
    className,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default Container;
