import { Link } from 'react-router-dom';
import type { ICellRendererParams, ColDef } from '@ag-grid-community/core';
import { formatDateEDT } from '@/lib/date';

// define the row data type
type RowData = {
  id: string;
  legalName: string;
  dbas: string[]; // TODO: Change to Contact type
  domains: string[]; // TODO: Change to Domain type
  address: string; // TODO: Change to Address type
  phone: string;
  lastActivity: string;
  city: string;
  country: string;
  industry: string;
};

function NameLinkCell({ value, data }: ICellRendererParams<RowData, string>) {
  if (!data?.id || !value)
    return null;
  return (
    <Link to={`/app/contacts/${data.id}`} className="text-primary hover:underline">
      {value}
    </Link>
  );
}

// Column Definitions
export const companyColumnDefs: ColDef<RowData>[] = [
  {
    field: 'legalName',
    headerName: 'Legal Name',
    width: 200,
    cellRenderer: NameLinkCell,
  },
  {
    field: 'dbas',
    headerName: 'DBAs',
    width: 250,
  },
  {
    field: 'domains',
    headerName: 'Company Domains',
    width: 200,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 150,
  },
  {
    field: 'lastActivity',
    headerName: 'Last Activity',
    width: 150,
    cellRenderer: LastActivityCell,
  },
  {
    field: 'city',
    headerName: 'City',
    width: 200,
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 200,
  },
  {
    field: 'industry',
    headerName: 'Industry',
    width: 200,
  },
];

// Cell renderers
function LastActivityCell({ value }: ICellRendererParams<RowData, string>) {
  if (!value || typeof value !== 'string') {
    return <div className="text-muted-foreground">Invalid date</div>;
  }

  const date = new Date(value);
  if (Number.isNaN(date.getTime())) {
    return <div className="text-muted-foreground">Invalid date</div>;
  }

  return <div className="text-muted-foreground">{formatDateEDT(date)}</div>;
}

// Sample data export
export const sampleContactData: RowData[] = [
  {
    id: '1',
    legalName: 'Acme Corporation',
    dbas: ['Acme Inc', 'Acme Tech'],
    domains: ['acme.com', 'acmetech.com'],
    address: '123 Main St',
    phone: '555-0123',
    lastActivity: new Date().toISOString(),
    city: 'San Francisco',
    country: 'USA',
    industry: 'Technology',
  },
  {
    id: '2',
    legalName: 'Global Industries',
    dbas: ['Global Tech'],
    domains: ['globalind.com'],
    address: '456 Market St',
    phone: '555-0124',
    lastActivity: new Date().toISOString(),
    city: 'New York',
    country: 'USA',
    industry: 'Manufacturing',
  },
];
