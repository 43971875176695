import { Mail, Phone, User } from 'lucide-react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';

interface ContactsPanelProps {
  dealId?: string;
}

interface Contact {
  id: string;
  name: string;
  role: string;
  email: string;
  phone: string;
  type: 'registrant' | 'verification';
}

interface DomainContacts {
  domain: string;
  contacts: Contact[];
}

export function ContactsPanel({ dealId }: ContactsPanelProps) {
  // This would typically fetch data from an API
  const domainContacts: DomainContacts[] = [
    {
      domain: 'lloyds.bank',
      contacts: [
        {
          id: '1',
          name: 'James Wilson',
          role: 'Domain Administrator',
          email: 'james.wilson@lloydsbanking.com',
          phone: '+44 20 7123 4567',
          type: 'registrant',
        },
        {
          id: '2',
          name: 'Sarah Thompson',
          role: 'Verification Officer',
          email: 'sarah.thompson@lloydsbanking.com',
          phone: '+44 20 7123 4568',
          type: 'verification',
        },
      ],
    },
    {
      domain: 'lloyds.insurance',
      contacts: [
        {
          id: '3',
          name: 'James Wilson',
          role: 'Domain Administrator',
          email: 'james.wilson@lloydsbanking.com',
          phone: '+44 20 7123 4567',
          type: 'registrant',
        },
        {
          id: '4',
          name: 'Sarah Thompson',
          role: 'Verification Officer',
          email: 'sarah.thompson@lloydsbanking.com',
          phone: '+44 20 7123 4568',
          type: 'verification',
        },
      ],
    },
  ];

  const getTypeLabel = (type: Contact['type']) => {
    const labels = {
      registrant: 'Registrant Contact',
      verification: 'Verification Contact',
    };
    return labels[type];
  };

  return (
    <ScrollArea className="h-full">
      <div className="space-y-4 p-4">
        <Accordion type="single" collapsible className="w-full">
          {domainContacts.map((domainContact, index) => (
            <AccordionItem key={domainContact.domain} value={`domain-${index}`}>
              <AccordionTrigger className="text-base font-semibold">
                {domainContact.domain}
              </AccordionTrigger>
              <AccordionContent>
                <div className="space-y-4 pt-2">
                  {domainContact.contacts.map((contact) => (
                    <Card key={contact.id}>
                      <CardHeader>
                        <CardTitle className="flex items-center gap-2 text-lg">
                          <User className="size-5" />
                          {getTypeLabel(contact.type)}
                        </CardTitle>
                      </CardHeader>
                      <CardContent className="space-y-4">
                        <div className="grid gap-4 md:grid-cols-2">
                          <div>
                            <div className="text-sm font-medium text-muted-foreground">Name</div>
                            <div>{contact.name}</div>
                          </div>
                          <div>
                            <div className="text-sm font-medium text-muted-foreground">Role</div>
                            <div>{contact.role}</div>
                          </div>
                          <div>
                            <div className="text-sm font-medium text-muted-foreground">
                              <span className="flex items-center gap-1">
                                <Mail className="size-4" />
                                Email
                              </span>
                            </div>
                            <a
                              href={`mailto:${contact.email}`}
                              className="text-primary hover:underline"
                            >
                              {contact.email}
                            </a>
                          </div>
                          <div>
                            <div className="text-sm font-medium text-muted-foreground">
                              <span className="flex items-center gap-1">
                                <Phone className="size-4" />
                                Phone
                              </span>
                            </div>
                            <a
                              href={`tel:${contact.phone}`}
                              className="text-primary hover:underline"
                            >
                              {contact.phone}
                            </a>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </ScrollArea>
  );
}
