import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Building2,
  Calendar,
  Database,
  FileText,
  Handshake,
  Search,
  Users,
} from 'lucide-react';
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';

const PAGES = [
  {
    id: 'deals',
    name: 'Deals',
    description: 'View and manage deals',
    icon: Handshake,
    path: '/app/deals',
  },
  {
    id: 'domains',
    name: 'Domains',
    description: 'View and manage domains',
    icon: Building2,
    path: '/app/domains',
  },
  {
    id: 'databases',
    name: 'Databases',
    description: 'View and manage databases',
    icon: Database,
    path: '/app/databases',
  },
  {
    id: 'tasks',
    name: 'Tasks',
    description: 'View and manage tasks',
    icon: FileText,
    path: '/app/tasks',
  },
  {
    id: 'scheduler',
    name: 'Scheduler',
    description: 'Schedule domain verifications',
    icon: Calendar,
    path: '/app/scheduler',
  },
  {
    id: 'contacts',
    name: 'Contacts',
    description: 'View and manage contacts',
    icon: Users,
    path: '/app/contacts',
  },
];

const ACTIONS = [
  {
    id: 'search-deals',
    name: 'Search Deals',
    description: 'Search through all deals',
    icon: Search,
    path: '/app/deals',
  },
  {
    id: 'search-domains',
    name: 'Search Domains',
    description: 'Search through all domains',
    icon: Search,
    path: '/app/domains',
  },
  {
    id: 'search-databases',
    name: 'Search Databases',
    description: 'Search through all databases',
    icon: Search,
    path: '/app/databases',
  },
];

export function AppCommandPalette() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  const handleSelect = useCallback((path: string) => {
    navigate(path);
    setOpen(false);
  }, [navigate]);

  return (
    <CommandDialog open={open} onOpenChange={setOpen}>
      <CommandInput placeholder="Type a command or search..." />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup heading="Pages">
          {PAGES.map((page) => (
            <CommandItem
              key={page.id}
              onSelect={() => handleSelect(page.path)}
              className="flex items-center gap-2"
            >
              <page.icon className="size-4" />
              <div>
                <div className="font-medium">{page.name}</div>
                <div className="text-sm text-muted-foreground">{page.description}</div>
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
        <CommandGroup heading="Quick Actions">
          {ACTIONS.map((action) => (
            <CommandItem
              key={action.id}
              onSelect={() => handleSelect(action.path)}
              className="flex items-center gap-2"
            >
              <action.icon className="size-4" />
              <div>
                <div className="font-medium">{action.name}</div>
                <div className="text-sm text-muted-foreground">{action.description}</div>
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  );
}
