import type { ICellRendererParams, ColDef } from '@ag-grid-community/core';
import { format } from 'date-fns';

export type ScheduledBatch = {
  id: string;
  scheduledFor: string;
  batchNumber: number;
  batchSize: number;
  tldTypes: ('bank' | 'insurance')[];
  registrars: string[];
  geographicRegions: string[];
  assignedAgent: string;
  status: 'pending' | 'in_progress' | 'completed' | 'failed';
};

function DateCell({ value }: ICellRendererParams<ScheduledBatch, string>) {
  if (!value)
    return null;
  return (
    <div className="text-muted-foreground">
      {format(new Date(value), 'MMM d, yyyy h:mm a')}
    </div>
  );
}

function ArrayCell({ value }: ICellRendererParams<ScheduledBatch, string[]>) {
  if (!value?.length)
    return null;
  return (
    <div className="flex flex-wrap gap-1">
      {value.map((item) => (
        <span key={item} className="rounded-full bg-muted px-2 py-0.5 text-xs">
          {item}
        </span>
      ))}
    </div>
  );
}

function StatusCell({ value }: ICellRendererParams<ScheduledBatch, ScheduledBatch['status']>) {
  if (!value)
    return null;

  const styles: Record<ScheduledBatch['status'], string> = {
    pending: 'bg-yellow-100 text-yellow-800',
    in_progress: 'bg-blue-100 text-blue-800',
    completed: 'bg-green-100 text-green-800',
    failed: 'bg-red-100 text-red-800',
  };

  const labels: Record<ScheduledBatch['status'], string> = {
    pending: 'Pending',
    in_progress: 'In Progress',
    completed: 'Completed',
    failed: 'Failed',
  };

  return (
    <span className={`rounded-full px-2 py-1 text-xs font-medium ${styles[value]}`}>
      {labels[value]}
    </span>
  );
}

export const schedulerColumnDefs: ColDef<ScheduledBatch>[] = [
  {
    field: 'scheduledFor',
    headerName: 'Scheduled For',
    flex: 1.5,
    cellRenderer: DateCell,
    sortable: true,
    filter: true,
  },
  {
    field: 'batchNumber',
    headerName: 'Batch #',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'batchSize',
    headerName: 'Domains',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'tldTypes',
    headerName: 'TLD Types',
    flex: 1.5,
    cellRenderer: ArrayCell,
    sortable: true,
    filter: true,
  },
  {
    field: 'registrars',
    headerName: 'Registrars',
    flex: 1.5,
    cellRenderer: ArrayCell,
    sortable: true,
    filter: true,
  },
  {
    field: 'geographicRegions',
    headerName: 'Geographic',
    flex: 1.5,
    cellRenderer: ArrayCell,
    sortable: true,
    filter: true,
  },
  {
    field: 'assignedAgent',
    headerName: 'Assigned Agent',
    flex: 1.5,
    sortable: true,
    filter: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    cellRenderer: StatusCell,
    sortable: true,
    filter: true,
  },
];

// Sample data for development
export const sampleScheduledData: ScheduledBatch[] = [
  {
    id: '1',
    scheduledFor: '2024-02-20T09:00:00Z',
    batchNumber: 1,
    batchSize: 12,
    tldTypes: ['bank'],
    registrars: ['CSC Enterprise', 'CSC Corporate'],
    geographicRegions: ['United States'],
    assignedAgent: 'John Smith',
    status: 'pending',
  },
  {
    id: '2',
    scheduledFor: '2024-02-21T14:30:00Z',
    batchNumber: 2,
    batchSize: 8,
    tldTypes: ['insurance'],
    registrars: ['MarkMonitor'],
    geographicRegions: ['European Union'],
    assignedAgent: 'Jane Doe',
    status: 'in_progress',
  },
  {
    id: '3',
    scheduledFor: '2024-02-22T10:00:00Z',
    batchNumber: 3,
    batchSize: 15,
    tldTypes: ['bank', 'insurance'],
    registrars: ['CSC Enterprise', 'MarkMonitor'],
    geographicRegions: ['United Kingdom'],
    assignedAgent: 'Bob Wilson',
    status: 'pending',
  },
];
