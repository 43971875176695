import * as React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import {
  BellRing,
  Settings,
  LayoutDashboard,
  Globe,
  ClipboardList,
  Database,
  BookTemplate,
  Contact2,
  ChartColumnIncreasing,
  CalendarClock,
  Command,
  BookOpen,
  Mail,
} from 'lucide-react';

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
  useSidebar,
} from '@/components/ui/sidebar';
import { cn } from '@/lib/utils';
import { NavMain } from './nav-main';
import { NavSecondary } from './nav-secondary';
import { NavUser } from './nav-user';

const data = {
  navMain: [
    {
      title: 'Dashboard',
      url: '/app/dashboard',
      icon: LayoutDashboard,
      isActive: true,
    },
    {
      title: 'Deals',
      url: '/app/deals',
      icon: BookOpen,
    },
    {
      title: 'Domains',
      url: '/app/domains',
      icon: Globe,
    },
    {
      title: 'Tasks',
      url: '/app/tasks',
      icon: ClipboardList,
    },
    {
      title: 'Databases',
      url: '/app/databases',
      icon: Database,
    },
    {
      title: 'Templates',
      url: '/app/templates',
      icon: BookTemplate,
    },
    {
      title: 'Contacts',
      url: '/app/contacts',
      icon: Contact2,
    },
    {
      title: 'Messages',
      url: '/app/messages',
      icon: Mail,
    },
    {
      title: 'Scheduler',
      url: '/app/scheduler',
      icon: CalendarClock,
    },
    {
      title: 'Reports',
      url: '/app/reports',
      icon: ChartColumnIncreasing,
    },
  ],
  navSecondary: [
    {
      title: 'Notifications',
      url: '#',
      icon: BellRing,
    },
    {
      title: 'Command Menu',
      url: '#',
      icon: Command,
      shortcut: '⌘K',
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        // Simulate ⌘K keypress
        const event = new KeyboardEvent('keydown', {
          key: 'k',
          metaKey: true,
          bubbles: true,
        });
        document.dispatchEvent(event);
      },
    },
    {
      title: 'System Settings',
      url: '/app/settings',
      icon: Settings,
    },
  ],
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { user } = useUser();
  console.log('app-sidebar user:', user);
  const sidebar = useSidebar();

  const userInfo = {
    name: user?.fullName || 'Unnamed User',
    email: user?.primaryEmailAddress?.emailAddress || '',
    avatar: user?.imageUrl || '/favicon.png',
  };

  return (
    <Sidebar className="bg-ftld-gray text-white" collapsible="icon" {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              className="transition duration-300 ease-in-out hover:bg-ftld-gray-light hover:text-ftld-secondary active:bg-ftld-gray-light active:text-ftld-secondary"
              size="lg"
              asChild
            >
              <Link to="/app/dashboard" className="flex items-center">
                {/* Small icon */}
                <div className={cn(
                  'aspect-square size-8 items-center justify-center rounded-full',
                  sidebar.state === 'collapsed' ? 'flex' : 'hidden',
                )}
                >
                  <img src="/assets/favicon.png" alt="fTLD Logo" className="size-8" />
                </div>
                {/* Full logo */}
                <div className={cn(
                  'flex-1 text-left text-sm leading-tight',
                  sidebar.state === 'collapsed' ? 'hidden' : 'block',
                )}
                >
                  <img src="/assets/ftld-logo.svg" alt="fTLD Logo" className="h-10 w-48" />
                </div>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>

      <SidebarContent>
        <NavMain items={data.navMain} />
        <NavSecondary items={data.navSecondary} className="mt-auto" />
      </SidebarContent>
      <SidebarFooter className="flex flex-col gap-4">
        <NavUser user={userInfo} />
        <div className={cn(
          'text-center text-xs text-white/70',
          'group-data-[collapsible=icon]:hidden',
        )}
        >
          ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          fTLD Registry Services, LLC
        </div>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
