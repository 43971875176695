import { Outlet } from 'react-router-dom';
import Footer from '@/components/footer';
import HeaderLanding from '@/components/public-header';

function PublicLayout() {
  return (
    <div className="flex min-h-svh flex-col justify-between">
      <HeaderLanding />
      <Outlet />
      <Footer />
    </div>
  );
}

export default PublicLayout;
