import { createTheme } from '@mui/material';

import {
  primary as ftldPrimary,
  secondary as ftldSecondary,
} from './colors';

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: ftldPrimary,
    secondary: ftldSecondary,
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
});
