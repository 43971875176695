import { SignIn } from '@clerk/clerk-react';

function LoginPage() {
  return (
    <main className="flex-1 grow">
      <div className="mt-4 flex justify-center lg:mt-8">
        <SignIn path="/login" fallbackRedirectUrl="/app" />
      </div>
    </main>
  );
}

export default LoginPage;
