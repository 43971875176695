// src/pages/app/deals/components/communications-panel.tsx
import { useEffect, useRef, useState } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { MessageInput } from './message-input';

interface Message {
  id: string;
  content: string;
  sender: {
    name: string;
    avatar?: string;
    email: string;
  };
  timestamp: string;
  isExternal: boolean;
  attachments?: {
    id: string;
    name: string;
    type: string;
    url: string;
    size: number;
  }[];
}

interface CommunicationsPanelProps {
  dealId?: string;
}

export function CommunicationsPanel({ dealId }: CommunicationsPanelProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([
    {
      id: '1',
      content: 'Initial verification request sent',
      sender: {
        name: 'System',
        email: 'system@ftld.com',
      },
      timestamp: new Date().toISOString(),
      isExternal: false,
    },
    {
      id: '2',
      content: 'Documents received from client. <p>Please review the following:</p><ul><li>Business License</li><li>Certificate of Good Standing</li><li>Domain Authorization Letter</li></ul>',
      sender: {
        name: 'John Doe',
        email: 'john.doe@example.com',
        avatar: 'https://api.dicebear.com/7.x/avatars/svg?seed=John',
      },
      timestamp: new Date(Date.now() - 1000 * 60 * 60).toISOString(), // 1 hour ago
      isExternal: true,
      attachments: [
        {
          id: '1',
          name: 'business-license.pdf',
          type: 'application/pdf',
          url: '#',
          size: 1024 * 1024 * 2.3, // 2.3MB
        },
      ],
    },
  ]);

  // Scroll to bottom when new messages arrive
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleSend = (content: string) => {
    const newMessage: Message = {
      id: crypto.randomUUID(),
      content,
      sender: {
        name: 'You',
        email: 'you@ftld.com',
        avatar: 'https://api.dicebear.com/7.x/avatars/svg?seed=You',
      },
      timestamp: new Date().toISOString(),
      isExternal: false,
    };

    setMessages((prev) => [...prev, newMessage]);
  };

  const handleUploadFiles = async (files: File[]) => {
    // In a real app, you would upload these files to your server
    // and get back URLs/IDs for them
    const uploadedFiles = files.map((file) => ({
      id: crypto.randomUUID(),
      name: file.name,
      type: file.type,
      url: URL.createObjectURL(file),
      size: file.size,
    }));

    const newMessage: Message = {
      id: crypto.randomUUID(),
      content: 'Uploaded files:',
      sender: {
        name: 'You',
        email: 'you@ftld.com',
        avatar: 'https://api.dicebear.com/7.x/avatars/svg?seed=You',
      },
      timestamp: new Date().toISOString(),
      isExternal: false,
      attachments: uploadedFiles,
    };

    setMessages((prev) => [...prev, newMessage]);
  };

  function formatFileSize(bytes: number): string {
    const units = ['B', 'KB', 'MB', 'GB'];
    let size = bytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(1)} ${units[unitIndex]}`;
  }

  return (
    <div className="flex h-full flex-col">
      <ScrollArea className="flex-1 px-4">
        <div className="space-y-4 py-4">
          {messages.map((message, index) => {
            const showDateDivider = index === 0 || new Date(message.timestamp).toDateString() !== new Date(messages[index - 1].timestamp).toDateString();

            return (
              <div key={message.id}>
                {showDateDivider && (
                  <div className="mb-4 flex items-center gap-2">
                    <Separator className="flex-1" />
                    <span className="text-xs font-medium text-muted-foreground">
                      {new Date(message.timestamp).toLocaleDateString()}
                    </span>
                    <Separator className="flex-1" />
                  </div>
                )}
                <div className="flex gap-3">
                  <Avatar className="size-8">
                    {message.sender.avatar
                      ? (
                          <AvatarImage src={message.sender.avatar} alt={message.sender.name} />
                        )
                      : null}
                    <AvatarFallback>
                      {message.sender.name.charAt(0)}
                    </AvatarFallback>
                  </Avatar>
                  <div className="flex-1">
                    <div className="flex items-baseline justify-between">
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-medium">
                          {message.sender.name}
                        </span>
                        {message.isExternal && (
                          <span className="rounded-full bg-blue-100 px-2 py-0.5 text-xs text-blue-800">
                            External
                          </span>
                        )}
                      </div>
                      <span className="text-xs text-muted-foreground">
                        {new Date(message.timestamp).toLocaleTimeString()}
                      </span>
                    </div>
                    <div
                      className="prose prose-sm mt-1 max-w-none"
                      dangerouslySetInnerHTML={{ __html: message.content }}
                    />
                    {message.attachments?.length
                      ? (
                          <div className="mt-2 space-y-2">
                            {message.attachments.map((file) => (
                              <div
                                key={file.id}
                                className="flex items-center gap-2 rounded-md border bg-muted/40 p-2 text-sm"
                              >
                                <div className="flex-1 truncate">
                                  {file.name}
                                </div>
                                <div className="text-xs text-muted-foreground">
                                  {formatFileSize(file.size)}
                                </div>
                              </div>
                            ))}
                          </div>
                        )
                      : null}
                  </div>
                </div>
              </div>
            );
          })}
          <div ref={scrollRef} />
        </div>
      </ScrollArea>
      <Separator />
      <MessageInput
        onSend={handleSend}
        onUploadFiles={handleUploadFiles}
      />
    </div>
  );
}
