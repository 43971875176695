import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import type { GridApi } from '@ag-grid-community/core';
import { format } from 'date-fns';
import { ArrowLeft, CalendarIcon, Clock, RotateCcw } from 'lucide-react';
import { toast } from 'sonner';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import FormInput from '@/components/form-components/form-input';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Input } from '@/components/ui/input';
import { MultiCombobox } from '@/components/ui/multi-combobox';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { previewColumnDefs, type PreviewDomain } from '../components/preview-grid-columns';

const registrars = [
  { value: 'csc-enterprise', label: 'CSC Enterprise Domains' },
  { value: 'csc-corporate', label: 'CSC Corporate Domains' },
  { value: 'markmonitor', label: 'MarkMonitor' },
];

const geographicRegions = [
  { value: 'us', label: 'United States' },
  { value: 'gb', label: 'United Kingdom' },
  { value: 'ca', label: 'Canada' },
  { value: 'eu', label: 'European Union' },
];

const tldTypes = [
  { value: 'bank', label: 'Bank' },
  { value: 'insurance', label: 'Insurance' },
];

const verificationAgents = [
  { value: 'agent1', label: 'John Smith' },
  { value: 'agent2', label: 'Jane Doe' },
  { value: 'agent3', label: 'Bob Wilson' },
];

// Sample data for development
const sampleData: PreviewDomain[] = [
  {
    id: '1',
    domain: 'chase.bank',
    registrantOrg: 'JPMorgan Chase Bank, N.A.',
    registrar: 'csc-enterprise',
    tldType: 'bank',
    geographic: 'us',
    lastVerifiedAt: '2023-05-15T00:00:00Z',
    status: 'active',
  },
  {
    id: '2',
    domain: 'statefarm.insurance',
    registrantOrg: 'State Farm Insurance',
    registrar: 'csc-corporate',
    tldType: 'insurance',
    geographic: 'us',
    lastVerifiedAt: '2023-02-10T00:00:00Z',
    status: 'active',
  },
  {
    id: '3',
    domain: 'wellsfargo.bank',
    registrantOrg: 'Wells Fargo Bank',
    registrar: 'csc-enterprise',
    tldType: 'bank',
    geographic: 'us',
    lastVerifiedAt: '2023-01-01T00:00:00Z',
    status: 'active',
  },
  {
    id: '4',
    domain: 'barclays.bank',
    registrantOrg: 'Barclays Bank PLC',
    registrar: 'csc-corporate',
    tldType: 'bank',
    geographic: 'gb',
    lastVerifiedAt: '2023-08-20T00:00:00Z',
    status: 'active',
  },
  {
    id: '5',
    domain: 'allianz.insurance',
    registrantOrg: 'Allianz SE',
    registrar: 'markmonitor',
    tldType: 'insurance',
    geographic: 'eu',
    lastVerifiedAt: '2023-06-15T00:00:00Z',
    status: 'active',
  },
  {
    id: '6',
    domain: 'rbc.bank',
    registrantOrg: 'Royal Bank of Canada',
    registrar: 'csc-enterprise',
    tldType: 'bank',
    geographic: 'ca',
    lastVerifiedAt: '2023-03-10T00:00:00Z',
    status: 'active',
  },
  {
    id: '7',
    domain: 'aviva.insurance',
    registrantOrg: 'Aviva plc',
    registrar: 'markmonitor',
    tldType: 'insurance',
    geographic: 'gb',
    lastVerifiedAt: '2023-04-20T00:00:00Z',
    status: 'active',
  },
  {
    id: '8',
    domain: 'cibc.bank',
    registrantOrg: 'Canadian Imperial Bank of Commerce',
    registrar: 'csc-enterprise',
    tldType: 'bank',
    geographic: 'ca',
    lastVerifiedAt: '2023-07-05T00:00:00Z',
    status: 'active',
  },
  {
    id: '9',
    domain: 'axa.insurance',
    registrantOrg: 'AXA SA',
    registrar: 'markmonitor',
    tldType: 'insurance',
    geographic: 'eu',
    lastVerifiedAt: '2023-09-30T00:00:00Z',
    status: 'active',
  },
  {
    id: '10',
    domain: 'hsbc.bank',
    registrantOrg: 'HSBC Holdings plc',
    registrar: 'csc-corporate',
    tldType: 'bank',
    geographic: 'gb',
    lastVerifiedAt: '2023-10-15T00:00:00Z',
    status: 'active',
  },
];

type Stage = 'filter' | 'grid' | 'schedule';

type FilterFormValues = {
  tldTypes: string[];
  registrars: string[];
  geographicRegions: string[];
  batchSize: number;
};

type ScheduleFormValues = {
  date: Date;
  time: string;
  assignedAgent: string;
};

// Generate time options in 30-minute intervals
const timeOptions = Array.from({ length: 48 }, (_, i) => {
  const hour = Math.floor(i / 2);
  const minute = i % 2 === 0 ? '00' : '30';
  const ampm = hour < 12 ? 'AM' : 'PM';
  const hour12 = (hour === 0 ? 12 : (hour > 12 ? hour - 12 : hour));
  return `${hour12}:${minute} ${ampm}`;
});

function SchedulePage() {
  const navigate = useNavigate();
  const [stage, setStage] = useState<Stage>('filter');
  const [searchQuery, setSearchQuery] = useState('');
  const [previewData, setPreviewData] = useState<PreviewDomain[]>([]);
  const gridApiRef = useRef<GridApi<PreviewDomain> | null>(null);

  const filterForm = useForm<FilterFormValues>({
    defaultValues: {
      tldTypes: [],
      registrars: [],
      geographicRegions: [],
      batchSize: 10,
    },
  });

  const scheduleForm = useForm<ScheduleFormValues>({
    defaultValues: {
      date: new Date(),
      time: '9:00 AM',
      assignedAgent: '',
    },
  });

  const handleFilterSubmit = async (data: FilterFormValues) => {
    try {
      // Filter domains based on selected criteria
      let filteredDomains = [...sampleData];

      if (data.tldTypes.length > 0) {
        filteredDomains = filteredDomains.filter((domain) =>
          data.tldTypes.includes(domain.tldType),
        );
      }

      if (data.registrars.length > 0) {
        filteredDomains = filteredDomains.filter((domain) =>
          data.registrars.includes(domain.registrar),
        );
      }

      if (data.geographicRegions.length > 0) {
        filteredDomains = filteredDomains.filter((domain) =>
          data.geographicRegions.includes(domain.geographic),
        );
      }

      // Sort by last verification date (oldest first)
      filteredDomains.sort((a, b) =>
        new Date(a.lastVerifiedAt).getTime() - new Date(b.lastVerifiedAt).getTime(),
      );

      // Group domains into batches
      const batches: PreviewDomain[][] = [];
      for (let i = 0; i < filteredDomains.length; i += data.batchSize) {
        batches.push(filteredDomains.slice(i, i + data.batchSize));
      }

      // Set the first batch as the preview data
      setPreviewData(batches[0] || []);
      setStage('grid');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const handleScheduleSubmit = async (data: ScheduleFormValues) => {
    try {
      // This would be replaced with actual API call
      console.log('Schedule verification:', {
        ...data,
        domains: previewData,
      });
      toast.success('Verification scheduled successfully');
      navigate('/app/scheduler');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const handleBack = () => {
    switch (stage) {
      case 'filter': {
        navigate('/app/scheduler');
        break;
      }
      case 'grid': {
        setStage('filter');
        break;
      }
      case 'schedule': {
        setStage('grid');
        break;
      }
    }
  };

  const handleGridReady = (params: { api: GridApi<PreviewDomain> }) => {
    gridApiRef.current = params.api;
  };

  const clearFilters = () => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      setSearchQuery('');
    }
  };

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-6 flex items-center gap-4">
        <Button variant="ghost" size="icon" onClick={handleBack}>
          <ArrowLeft className="size-5" />
        </Button>
        <div>
          <h1 className="text-xl font-bold">
            {stage === 'filter' && 'Initial Filters'}
            {stage === 'grid' && 'Eligible Domains'}
            {stage === 'schedule' && 'Schedule Verification'}
          </h1>
          {stage === 'filter' && (
            <p className="text-sm text-muted-foreground">
              Set initial filters to find eligible domains
            </p>
          )}
          {stage === 'grid' && (
            <p className="text-sm text-muted-foreground">
              All shown domains will be included in the verification
            </p>
          )}
          {stage === 'schedule' && (
            <p className="text-sm text-muted-foreground">
              Set verification date and assign agent
            </p>
          )}
        </div>
      </div>

      {stage === 'filter' && (
        <form onSubmit={filterForm.handleSubmit(handleFilterSubmit)} className="space-y-6">
          <div className="grid gap-6 md:grid-cols-2">
            <FormInput
              control={filterForm.control}
              name="batchSize"
              label="Batch Size"
              type="number"
              required
              rules={{ required: 'Batch size is required', min: 1 }}
            />

            <div className="space-y-2">
              <label className="text-sm font-medium">TLD Types</label>
              <MultiCombobox
                options={tldTypes}
                selected={filterForm.watch('tldTypes')}
                onChange={(value) => filterForm.setValue('tldTypes', value)}
                placeholder="Select TLD types"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Registrars</label>
              <MultiCombobox
                options={registrars}
                selected={filterForm.watch('registrars')}
                onChange={(value) => filterForm.setValue('registrars', value)}
                placeholder="Select registrars"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Geographic Regions</label>
              <MultiCombobox
                options={geographicRegions}
                selected={filterForm.watch('geographicRegions')}
                onChange={(value) => filterForm.setValue('geographicRegions', value)}
                placeholder="Select regions"
              />
            </div>
          </div>

          <div className="flex justify-end gap-3">
            <Button
              type="button"
              variant="outline"
              onClick={() => filterForm.reset()}
            >
              Clear All
            </Button>
            <Button type="submit">
              Show Eligible Domains
            </Button>
          </div>
        </form>
      )}

      {stage === 'grid' && (
        <div className="space-y-6">
          <div className="mb-6 flex gap-4">
            <div className="flex-1">
              <Input
                type="search"
                placeholder="Search domains..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <Button
              variant="outline"
              onClick={clearFilters}
              className="gap-2"
            >
              <RotateCcw className="size-4" />
              Clear Filters
            </Button>
          </div>

          <AGDataGrid
            columnDefs={previewColumnDefs}
            rowData={previewData}
            quickFilterText={searchQuery}
            onGridReady={handleGridReady}
            domLayout="autoHeight"
            defaultColDef={{
              sortable: true,
              filter: true,
              autoHeight: true,
              wrapText: true,
            }}
          />

          <div className="flex justify-end">
            <Button onClick={() => setStage('schedule')}>
              Continue to Schedule
            </Button>
          </div>
        </div>
      )}

      {stage === 'schedule' && (
        <form onSubmit={scheduleForm.handleSubmit(handleScheduleSubmit)} className="space-y-6">
          <div className="grid gap-6 md:grid-cols-2">
            <div className="space-y-2">
              <label className="text-sm font-medium">Date</label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className={cn(
                      'w-full justify-start text-left font-normal',
                      !scheduleForm.watch('date') && 'text-muted-foreground',
                    )}
                  >
                    <CalendarIcon className="mr-2 size-4" />
                    {scheduleForm.watch('date') ? format(scheduleForm.watch('date'), 'PPP') : <span>Pick a date</span>}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={scheduleForm.watch('date')}
                    onSelect={(date) => date && scheduleForm.setValue('date', date)}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Time</label>
              <Select
                value={scheduleForm.watch('time')}
                onValueChange={(value) => scheduleForm.setValue('time', value)}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select time">
                    <div className="flex items-center">
                      <Clock className="mr-2 size-4" />
                      {scheduleForm.watch('time')}
                    </div>
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {timeOptions.map((time) => (
                    <SelectItem key={time} value={time}>
                      {time}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Assigned Verification Agent</label>
              <Select
                value={scheduleForm.watch('assignedAgent')}
                onValueChange={(value) => scheduleForm.setValue('assignedAgent', value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select agent" />
                </SelectTrigger>
                <SelectContent>
                  {verificationAgents.map((agent) => (
                    <SelectItem key={agent.value} value={agent.value}>
                      {agent.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="flex justify-end gap-3">
            <Button variant="outline" onClick={handleBack}>
              Back to Grid
            </Button>
            <Button type="submit" disabled={scheduleForm.formState.isSubmitting}>
              Schedule Verification
            </Button>
          </div>
        </form>
      )}
    </Container>
  );
}

export default SchedulePage;
