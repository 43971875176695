import { useState } from 'react';
import AGDataGrid from '@/components/ag-data-grid';
import ColumnStatistics from '@/components/card-statistics-column';
import Container from '@/components/container';
import { Input } from '@/components/ui/input';
import { domainColumnDefs } from './domain-grid-columns';

const bankStats = {
  'New Registrations': 45,
  'Renewal Rate': '93.7%',
};
const insuranceStats = {
  'New Registrations': 14,
  'Renewal Rate': '91.1%',
};

const sampleDomainData = [
  {
    id: 1,
    domain: 'chase.bank',
    registrantOrg: 'JPMorgan Chase Bank, N.A.',
    registrar: 'CSC Enterprise Domains',
    createDate: '2015-06-15T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'clientTransferProhibited',
  },
  {
    id: 2,
    domain: 'statefarm.insurance',
    registrantOrg: 'State Farm Mutual Automobile Insurance Company',
    registrar: 'CSC Corporate Domains',
    createDate: '2016-03-22T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'serverTransferProhibited',
  },
  {
    id: 3,
    domain: 'wellsfargo.bank',
    registrantOrg: 'Wells Fargo Bank, N.A.',
    registrar: 'CSC Enterprise Domains',
    createDate: '2015-07-10T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'ok',
  },
  {
    id: 4,
    domain: 'progressive.insurance',
    registrantOrg: 'Progressive Casualty Insurance Company',
    registrar: 'MarkMonitor',
    createDate: '2016-04-30T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'pendingTransfer',
  },
  {
    id: 5,
    domain: 'barclays.bank',
    registrantOrg: 'Barclays Bank PLC',
    registrar: 'CSC Corporate Domains',
    createDate: '2015-09-05T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'clientHold',
  },
  {
    id: 6,
    domain: 'allianz.insurance',
    registrantOrg: 'Allianz SE',
    registrar: 'MarkMonitor',
    createDate: '2016-05-01T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'serverHold',
  },
  {
    id: 7,
    domain: 'tdbank.bank',
    registrantOrg: 'TD Bank, N.A.',
    registrar: 'CSC Enterprise Domains',
    createDate: '2015-08-18T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'addPeriod',
  },
  {
    id: 8,
    domain: 'metlife.insurance',
    registrantOrg: 'Metropolitan Life Insurance Company',
    registrar: 'CSC Corporate Domains',
    createDate: '2016-02-15T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'redemptionPeriod',
  },
  {
    id: 9,
    domain: 'bankofamerica.bank',
    registrantOrg: 'Bank of America Corporation',
    registrar: 'CSC Enterprise Domains',
    createDate: '2015-06-22T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'pendingDelete',
  },
  {
    id: 10,
    domain: 'axa.insurance',
    registrantOrg: 'AXA SA',
    registrar: 'MarkMonitor',
    createDate: '2016-03-30T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'serverRenewProhibited',
  },
  {
    id: 11,
    domain: 'nationwide.insurance',
    registrantOrg: 'Nationwide Mutual Insurance Company',
    registrar: 'CSC Corporate Domains',
    createDate: '2016-04-15T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'locked',
  },
  {
    id: 12,
    domain: 'citibank.bank',
    registrantOrg: 'Citibank, N.A.',
    registrar: 'CSC Enterprise Domains',
    createDate: '2015-07-28T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'autoRenewPeriod',
  },
  {
    id: 13,
    domain: 'prudential.insurance',
    registrantOrg: 'Prudential Financial, Inc.',
    registrar: 'MarkMonitor',
    createDate: '2016-03-08T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'pendingRestore',
  },
  {
    id: 14,
    domain: 'usbank.bank',
    registrantOrg: 'U.S. Bank National Association',
    registrar: 'CSC Enterprise Domains',
    createDate: '2015-08-05T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'transferPeriod',
  },
  {
    id: 15,
    domain: 'travelers.insurance',
    registrantOrg: 'The Travelers Companies, Inc.',
    registrar: 'MarkMonitor',
    createDate: '2016-05-12T00:00:00Z',
    registryStatus: 'Active',
    websiteStatus: 'expired',
  },
];

function DomainsPage() {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  return (
    <Container className="mt-8 pb-12">
      <h1 className="mb-6 text-3xl font-bold">Domains</h1>
      <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
        <ColumnStatistics
          title=".BANK"
          statistics={bankStats}
        />
        <ColumnStatistics
          title=".INSURANCE"
          statistics={insuranceStats}
        />
      </div>
      <div className="mb-6">
        <Input type="search" placeholder="Search domains..." onChange={handleSearch} />
      </div>
      <div>
        <AGDataGrid
          columnDefs={domainColumnDefs}
          rowData={sampleDomainData}
          quickFilterText={searchTerm}
          rowSelection="multiple"
        />
      </div>
    </Container>
  );
}

export default DomainsPage;
