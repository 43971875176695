import { StrictMode } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { createRoot } from 'react-dom/client';
import App from './app';
import { AG_GRID_LICENSE_KEY } from './lib/globals';

// import global styles
import '@/styles/app.css';

// import fonts
import '@fontsource/inter/300.css'; // Light
import '@fontsource/inter/400.css'; // Regular
import '@fontsource/inter/500.css'; // Medium
import '@fontsource/inter/700.css'; // Bold

import '@fontsource/lora/400.css'; // Regular
import '@fontsource/lora/700.css';

// set ag grid license key
LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ClipboardModule,
  MenuModule,
]);

createRoot(document.querySelector('#root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
