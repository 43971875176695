import * as React from 'react';
import Dropzone, {
  type DropzoneProps,
  type FileRejection,
} from 'react-dropzone';
import { FileText, X } from 'lucide-react';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { toast } from 'sonner';
import Image from '@/components/image';

import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useControllableState } from '@/hooks/use-controllable-state';
import { cn, formatBytes } from '@/lib/utils';

// Define commonly accepted file types while excluding potentially dangerous ones
const defaultAccept: DropzoneProps['accept'] = {
  // Documents
  'application/pdf': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // .docx
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // .xlsx
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [], // .pptx
  'text/plain': [],
  'text/csv': [],
  'text/markdown': [],

  // Images
  'image/*': [],

  // Audio
  'audio/*': [],

  // Video
  'video/*': [],

  // Archives
  'application/zip': [],
  'application/x-zip-compressed': [],
  'application/x-7z-compressed': [],
  'application/x-rar-compressed': [],

  // Web
  'text/html': [],
  'text/css': [],
  'application/javascript': [],
  'application/json': [],
};

// Blocked file extensions (case-insensitive)
const BLOCKED_EXTENSIONS = new Set([
  // Executables
  'exe',
  'dll',
  'bat',
  'cmd',
  'com',
  'msi',
  'ps1',
  'scr',
  'vb',
  'vbs',
  'ws',
  'wsf',
  // Scripts that could be dangerous
  'sh',
  'bash',
  'php',
  'jar',
  // System files
  'sys',
  'reg',
]);

interface FileUploaderProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Value of the uploader.
   * @type File[]
   * @default undefined
   * @example value={files}
   */
  value?: File[];

  /**
   * Function to be called when the value changes.
   * @type (files: File[]) => void
   * @default undefined
   * @example onValueChange={(files) => setFiles(files)}
   */
  onValueChange?: (files: File[]) => void;

  /**
   * Function to be called when files are uploaded.
   * @type (files: File[]) => Promise<void>
   * @default undefined
   * @example onUpload={(files) => uploadFiles(files)}
   */
  onUpload?: (files: File[]) => Promise<void>;

  /**
   * Progress of the uploaded files.
   * @type Record<string, number> | undefined
   * @default undefined
   * @example progresses={{ "file1.png": 50 }}
   */
  progresses?: Record<string, number>;

  /**
   * Accepted file types for the uploader.
   * @type { [key: string]: string[]}
   * @default
   * ```ts
   * { "image/*": [] }
   * ```
   * @example accept={["image/png", "image/jpeg"]}
   */
  accept?: DropzoneProps['accept'];

  /**
   * Maximum file size for the uploader.
   * @type number | undefined
   * @default 1024 * 1024 * 2 // 2MB
   * @example maxSize={1024 * 1024 * 2} // 2MB
   */
  maxSize?: DropzoneProps['maxSize'];

  /**
   * Maximum number of files for the uploader.
   * @type number | undefined
   * @default 1
   * @example maxFileCount={4}
   */
  maxFileCount?: DropzoneProps['maxFiles'];

  /**
   * Whether the uploader should accept multiple files.
   * @type boolean
   * @default false
   * @example multiple
   */
  multiple?: boolean;

  /**
   * Whether the uploader is disabled.
   * @type boolean
   * @default false
   * @example disabled
   */
  disabled?: boolean;
}

export function FileUploader(props: FileUploaderProps) {
  const {
    value: valueProp,
    onValueChange,
    onUpload,
    progresses,
    accept = defaultAccept,
    maxSize = 1024 * 1024 * 25, // 25MB
    maxFileCount = 10,
    multiple = true,
    disabled = false,
    className,
    ...dropzoneProps
  } = props;

  const [files, setFiles] = useControllableState({
    prop: valueProp,
    onChange: onValueChange,
  });

  // Function to check if file extension is blocked
  const hasBlockedExtension = (filename: string) => {
    const extension = filename.split('.').pop()?.toLowerCase();
    return extension ? BLOCKED_EXTENSIONS.has(extension) : false;
  };
  const onDrop = React.useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      // Check for blocked extensions
      const blockedFiles = acceptedFiles.filter((file) => hasBlockedExtension(file.name));
      if (blockedFiles.length > 0) {
        for (const file of blockedFiles) {
          toast.error(`File ${file.name} is not allowed for security reasons`);
        }
        // Filter out blocked files
        acceptedFiles = acceptedFiles.filter((file) => !hasBlockedExtension(file.name));
      }
      if (!multiple && maxFileCount === 1 && acceptedFiles.length > 1) {
        toast.error('Cannot upload more than 1 file at a time');
        return;
      }

      if ((files?.length ?? 0) + acceptedFiles.length > maxFileCount) {
        toast.error(`Cannot upload more than ${maxFileCount} files`);
        return;
      }

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );

      const updatedFiles = files ? [...files, ...newFiles] : newFiles;

      setFiles(updatedFiles);

      if (rejectedFiles.length > 0) {
        for (const { file, errors } of rejectedFiles) {
          const errorMessages = errors.map((error) => error.message).join(', ');
          toast.error(`File ${file.name} was rejected: ${errorMessages}`);
        }
      }

      if (
        onUpload
        && updatedFiles.length > 0
        && updatedFiles.length <= maxFileCount
      ) {
        const target
          = updatedFiles.length > 0 ? `${updatedFiles.length} files` : `file`;

        toast.promise(onUpload(updatedFiles), {
          loading: `Uploading ${target}...`,
          success: () => {
            setFiles([]);
            return `${target} uploaded`;
          },
          error: `Failed to upload ${target}`,
        });
      }
    },

    [files, maxFileCount, multiple, onUpload, setFiles],
  );

  function onRemove(index: number) {
    if (!files)
      return;
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
    onValueChange?.(newFiles);
  }

  // Revoke preview url when component unmounts
  React.useEffect(() => {
    return () => {
      if (!files)
        return;
      for (const file of files) {
        if (isFileWithPreview(file)) {
          URL.revokeObjectURL(file.preview);
        }
      }
    };
  }, [files]);

  const isDisabled = disabled || (files?.length ?? 0) >= maxFileCount;

  return (
    <div className="relative flex flex-col gap-6 overflow-hidden">
      <Dropzone
        onDrop={onDrop}
        accept={accept}
        maxSize={maxSize}
        maxFiles={maxFileCount}
        multiple={maxFileCount > 1 || multiple}
        disabled={isDisabled}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps()}
            className={cn(
              'group relative grid h-52 w-full cursor-pointer place-items-center rounded-lg border-2 border-dashed border-muted-foreground/25 px-5 py-2.5 text-center transition hover:bg-muted/50',
              'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
              isDragActive && 'border-muted-foreground',
              isDisabled && 'pointer-events-none opacity-60',
              className,
            )}
            {...dropzoneProps}
          >
            <input {...getInputProps()} />
            {isDragActive
              ? (
                  <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
                    <div className=" mx-auto max-w-min rounded-lg border border-dashed border-muted-foreground/50 p-3">
                      <IoCloudUploadOutline className="size-8 text-foreground" />
                    </div>
                    <div className="flex flex-col gap-px">
                      <p className="font-medium text-muted-foreground">
                        Drop your files here
                      </p>
                      <p className="text-sm text-muted-foreground/70">
                        You can upload
                        {maxFileCount > 1
                          ? ` ${maxFileCount === Infinity ? 'multiple' : maxFileCount}
                      files (up to ${formatBytes(maxSize)} each)`
                          : ` a file with ${formatBytes(maxSize)}`}
                      </p>
                    </div>
                  </div>
                )
              : (
                  <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
                    <div className=" mx-auto max-w-min rounded-lg border border-dashed p-3">
                      <IoCloudUploadOutline className="size-8 text-muted-foreground" />
                    </div>
                    <div className="flex flex-col gap-px">
                      <p className="font-medium text-muted-foreground">
                        Drag files here, or click to select files.
                      </p>
                      <p className="text-sm text-muted-foreground/70">
                        You can upload
                        {maxFileCount > 1
                          ? ` ${maxFileCount === Infinity ? 'multiple' : maxFileCount}
                      files (up to ${formatBytes(maxSize)} each)`
                          : ` a file with ${formatBytes(maxSize)}`}
                      </p>
                    </div>
                  </div>
                )}
          </div>
        )}
      </Dropzone>
      {files?.length
        ? (
            <ScrollArea className="h-fit w-full px-3">
              <div className="flex max-h-48 flex-col gap-4">
                {files?.map((file, index) => (
                  <FileCard
                    key={file.name}
                    file={file}
                    onRemove={() => onRemove(index)}
                    progress={progresses?.[file.name]}
                  />
                ))}
              </div>
            </ScrollArea>
          )
        : null}
    </div>
  );
}

interface FileCardProps {
  file: File;
  onRemove: () => void;
  progress?: number;
}

function FileCard({ file, progress, onRemove }: FileCardProps) {
  return (
    <div className="relative flex items-center gap-2.5">
      <div className="flex flex-1 gap-2.5">
        {isFileWithPreview(file) ? <FilePreview file={file} /> : null}
        <div className="flex w-full flex-col gap-2">
          <div className="flex flex-col gap-px">
            <p className="line-clamp-1 text-sm font-medium text-foreground/80">
              {file.name}
            </p>
            <p className="text-xs text-muted-foreground">
              {formatBytes(file.size)}
            </p>
          </div>
          {progress ? <Progress value={progress} /> : null}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Button
          type="button"
          variant="outline"
          size="icon"
          className="size-7"
          onClick={onRemove}
        >
          <X className="size-4" aria-hidden="true" />
          <span className="sr-only">Remove file</span>
        </Button>
      </div>
    </div>
  );
}

function isFileWithPreview(file: File): file is File & { preview: string } {
  return 'preview' in file && typeof file.preview === 'string';
}

interface FilePreviewProps {
  file: File & { preview: string };
}

function FilePreview({ file }: FilePreviewProps) {
  if (file.type.startsWith('image/')) {
    return (
      <Image
        src={file.preview}
        alt={file.name}
        loading="lazy"
        width={48}
        height={48}
        className="aspect-square shrink-0 rounded-md object-cover"
      />

    );
  }

  return (
    <FileText className="size-10 text-muted-foreground" aria-hidden="true" />
  );
}
