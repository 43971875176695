import React, { useState, useRef } from 'react';
import { ModuleRegistry } from '@ag-grid-community/core';
import type { GridApi } from '@ag-grid-community/core';
import type { AgGridReactProps } from '@ag-grid-community/react';
import { AgGridReact } from '@ag-grid-community/react';

import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { agDataGridTheme } from './theme';

ModuleRegistry.registerModules([
  ExcelExportModule,
  RichSelectModule,
  RangeSelectionModule,
  AdvancedFilterModule,
  RowGroupingModule,
]);

type AGDataGridProps<TData> = AgGridReactProps<TData>;

function AGDataGrid<TData>(props: AGDataGridProps<TData>): JSX.Element {
  const { quickFilterText, ...restProps } = props;

  const gridRef = useRef<AgGridReact>(null);
  const [_gridApi, setGridApi] = useState<GridApi<TData> | null>(null);

  const onGridReady = (params: { api: GridApi<TData> }) => {
    setGridApi(params.api);
    // call any onGridReady prop passed in from parent
    if (typeof onGridReady === 'function') {
      onGridReady(params);
    }
  };

  return (
    <AgGridReact
      ref={gridRef}
      onGridReady={onGridReady}
      theme={agDataGridTheme}
      domLayout="autoHeight"
      pagination
      paginationPageSize={10}
      paginationPageSizeSelector={[10, 25, 50, 100]}
      quickFilterText={quickFilterText}
      {...restProps}
    />

  );
}

export default AGDataGrid;
