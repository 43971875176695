import { useState } from 'react';
import { FileText, MoreVertical, Download, Trash2, Share2 } from 'lucide-react';
import { toast } from 'sonner';
import { CardEmpty } from '@/components/card-empty';
import Image from '@/components/image';
import ShareDialog from '@/components/share-dialog';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { useDeleteFile, useDownloadFile } from '@/hooks/use-drive';
import { cn, formatBytes } from '@/lib/utils';
import type { GoogleDriveFile } from '@/types/drive';

interface UploadedFilesCardProps {
  uploadedFiles: GoogleDriveFile[];
}

export function UploadedFilesCard({ uploadedFiles }: UploadedFilesCardProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Attached files</CardTitle>
      </CardHeader>
      <CardContent>
        {uploadedFiles.length > 0
          ? (
              <ScrollArea className={cn('max-h-96 w-full rounded-md', uploadedFiles.length > 4 && 'h-96')}>
                <div className="flex flex-col gap-4 pr-4">
                  {uploadedFiles.map((file) => (
                    <FileListItem key={file.id} file={file} />
                  ))}
                </div>
                <ScrollBar />
              </ScrollArea>
            )
          : (
              <CardEmpty
                title="No files uploaded"
                description="Upload files to see them here"
                className="w-full"
              />
            )}
      </CardContent>
    </Card>
  );
}

interface FileListItemProps {
  file: GoogleDriveFile;
}

function FileListItem({ file }: FileListItemProps) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const deleteMutation = useDeleteFile();
  const downloadQuery = useDownloadFile(file.id, {
    enabled: false,
    retry: false,
    staleTime: Infinity,
    gcTime: 0,
  });
  const isImage = file.mimeType.startsWith('image/');
  const date = new Date(file.createdTime).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const uploaderInfo = file.properties?.uploaderName || file.properties?.uploaderEmail;

  const handleView = () => {
    if (file.webViewLink) {
      window.open(file.webViewLink, '_blank', 'noopener,noreferrer');
    }
  };

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      const { data } = await downloadQuery.refetch();
      if (!data) {
        throw new Error('No data received');
      }

      // Create download URL
      const url = URL.createObjectURL(data);

      // Create and trigger download
      const downloadUrl = document.createElement('a');
      downloadUrl.style.display = 'none';
      downloadUrl.href = url;
      downloadUrl.download = file.name;
      document.body.append(downloadUrl);
      downloadUrl.click();

      // Clean up
      downloadUrl.remove();
      URL.revokeObjectURL(url);

      toast.success('Download started');
    } catch (error) {
      console.error('Download failed:', error);
      toast.error('Failed to download file');
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteMutation.mutateAsync(file.id);
      toast.success('File deleted successfully');
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Delete failed:', error);
      toast.error('Failed to delete file');
    }
  };

  return (
    <>
      <div className="flex items-center gap-3 rounded-lg border p-2 px-3">
        <div className="flex size-12 shrink-0 items-center justify-center rounded-md border bg-muted">
          {isImage && file.thumbnailLink
            ? (
                <Image
                  src={file.thumbnailLink}
                  alt={file.name}
                  width={48}
                  height={48}
                  className="aspect-square rounded-md object-cover"
                />
              )
            : (
                <FileText className="size-6 text-muted-foreground" aria-hidden="true" />
              )}
        </div>
        <div className="flex flex-1 flex-col gap-1">
          <p className="line-clamp-1 text-sm font-medium">
            {file.name}
          </p>
          <div className="flex items-center gap-2 text-xs text-muted-foreground">
            <span>{formatBytes(Number.parseInt(file.size ?? '0', 10))}</span>
            <span>•</span>
            <span>{date}</span>
            {uploaderInfo && (
              <>
                <span>•</span>
                <span>{uploaderInfo}</span>
              </>
            )}
          </div>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon" className="size-8">
              <MoreVertical className="size-4" />
              <span className="sr-only">Open action menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={handleDownload}
              disabled={isDownloading}
              className="flex items-center"
            >
              <Download className="mr-2 size-4" />
              <span className="flex-1">Download</span>
              {isDownloading && (
                <div className="ml-2 size-3 animate-spin rounded-full border-2 border-current border-t-transparent" />
              )}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setShareDialogOpen(true)}>
              <Share2 className="mr-2 size-4" />
              Share
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => setDeleteDialogOpen(true)}
              className="text-destructive focus:text-destructive"
            >
              <Trash2 className="mr-2 size-4" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Confirm Delete</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete the file "
              {file.name}
              "? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              {deleteMutation.isPending ? 'Deleting...' : 'Delete'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <ShareDialog
        fileId={file.id}
        fileName={file.name}
        open={shareDialogOpen}
        onOpenChange={setShareDialogOpen}
      />
    </>
  );
}
