import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';

interface RegistrantOrgPanelProps {
  dealId?: string;
}

export function RegistrantOrgPanel({ dealId }: RegistrantOrgPanelProps) {
  // This would typically fetch data from an API
  const registrantData = {
    name: 'Acme Corporation',
    type: 'Corporation',
    jurisdiction: 'Delaware, USA',
    registrationNumber: '12345-67',
    status: 'Active',
    incorporationDate: '2020-01-15',
    address: {
      street: '123 Business Ave',
      city: 'Tech City',
      state: 'CA',
      zip: '94105',
      country: 'United States',
    },
  };

  return (
    <ScrollArea className="h-full">
      <div className="space-y-4 p-4">
        <Card>
          <CardHeader>
            <CardTitle className="text-lg">Organization Details</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="text-sm font-medium text-muted-foreground">Name</div>
                <div>{registrantData.name}</div>
              </div>
              <div>
                <div className="text-sm font-medium text-muted-foreground">Type</div>
                <div>{registrantData.type}</div>
              </div>
              <div>
                <div className="text-sm font-medium text-muted-foreground">Jurisdiction</div>
                <div>{registrantData.jurisdiction}</div>
              </div>
              <div>
                <div className="text-sm font-medium text-muted-foreground">Registration Number</div>
                <div>{registrantData.registrationNumber}</div>
              </div>
              <div>
                <div className="text-sm font-medium text-muted-foreground">Status</div>
                <div>{registrantData.status}</div>
              </div>
              <div>
                <div className="text-sm font-medium text-muted-foreground">Incorporation Date</div>
                <div>{new Date(registrantData.incorporationDate).toLocaleDateString()}</div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="text-lg">Address</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-1">
              <div>{registrantData.address.street}</div>
              <div>
                {registrantData.address.city}
                ,
                {registrantData.address.state}
                {' '}
                {registrantData.address.zip}
              </div>
              <div>{registrantData.address.country}</div>
            </div>
          </CardContent>
        </Card>
      </div>
    </ScrollArea>
  );
}
