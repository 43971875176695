import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <main id="page-not-found" className="flex-1 grow">
      <div className="mt-16 flex grow flex-col items-center justify-center space-y-6">
        <div>
          <h2 className="text-3xl font-medium">Page Not Found.</h2>
        </div>
        <div>
          <Link to="/">
            <h3 className="text-2xl font-medium underline hover:text-ftld-primary">
              Return Home
            </h3>
          </Link>
        </div>
      </div>
    </main>
  );
}

export default NotFound;
